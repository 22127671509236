<template>
  <DefaultLayout>

    <UIContainer class="md:pb-[100px]">
      <UIPageHeader v-if="mobile.isMobile.value" :title="titleByPage">
        <template #search><span></span></template>
      </UIPageHeader>
      <div class="md:mt-[40px]">
        <div class="hidden md:block mb-[32px] font-medium text-[2.25rem] leading-[2.75rem] text-[#0A5CD5]">{{
          titleByPage
          }}
        </div>
        <div :class="[
          'flex justify-between gap-[20px] mb-[50px] md:mb-0',
        ]">
          <div class="bg-transparent" :class="[
            false ? 'bg-purple-700' : '',
            sidebarExpand ? 'static z-10 top-[112px] ml-[-1rem] mt-[-1rem] mr-[-1rem] w-screen bg-white ' : '',
          ]">


            <div v-if="mobile.isMobile.value" class="flex justify-end" :class="[
              sidebarExpand ? '!w-full' : ''
            ]" @click="sidebarExpand = !sidebarExpand">
              <UIIcon v-if="sidebarExpand" size="24" name="chevronDoubleLeft" class="p-[12px] bg-white rounded-[12px]">
              </UIIcon>

              <UIIcon v-else size="24" name="firsList" class="p-[12px] bg-white rounded-[12px] menu-shadow"></UIIcon>
            </div>

            <Sheet class="md:w-[317px] h-min !bg-transparent !p-0 md:!p-[1.7rem] !shadow-none md:!shadow-[4px_4px_24px_2px_rgba(10,92,213,0.1)]">
              <ButtonNew v-if="role === 'employee'" class="mt-[20px] mb-[20px] md:mb-[40px] " :class="[
                sidebarExpand ? '!w-[283px] ml-[calc((100vw-283px)/2.0)]' : ''
              ]" @click="toCreateResume">
                <UIIcon size="16" name="plus" color="white"></UIIcon>
                <div :class="[
                  'ml-[12px]',
                  mobile.isMobile.value ? 'hidden' : '',
                  sidebarExpand ? '!block' : '',
                ]">
                  Добавить резюме
                </div>

              </ButtonNew>
              <ButtonNew v-else-if="role === 'employer' && route.path !== '/employer/lk/internships'"
                class="mt-[20px] mb-[20px] md:mb-[40px] " :class="[
                  mobile.isMobile.value && sidebarExpand ? '!w-[283px] ml-[calc((100vw-283px)/2.0)]' : ''
                ]" @click="toCreateVacancy">
                <UIIcon size="16" name="plus" color="white"></UIIcon>

                <div :class="[
                  'ml-[12px]',
                  mobile.isMobile.value ? 'hidden' : '',
                  sidebarExpand ? '!block' : '',
                ]">
                  Добавить вакансию
                </div>

              </ButtonNew>
              <ButtonNew v-else-if="role === 'employer' && route.path === '/employer/lk/internships'"
                class="mt-[20px] mb-[20px] md:mb-[40px] " :class="[
                  mobile.isMobile.value && sidebarExpand ? '!w-[283px] ml-[calc((100vw-283px)/2.0)]' : ''
                ]" @click="toCreateInternship">
                <UIIcon size="16" name="plus" color="white"></UIIcon>

                <div :class="[
                  'ml-[12px]',
                  mobile.isMobile.value ? 'hidden' : '',
                  sidebarExpand ? '!block' : '',
                ]">
                  Добавить стажировку
                </div>

              </ButtonNew>

              <div class="flex flex-col gap-[12px] bg-transparent" :class="[sidebarExpand ? '!gap-0' : '']">
                <template v-for="item in menu" :key="item">
                  <template v-if="item">
                    <div class="w-full md:w-auto h-[48px] md:h-auto bg-white md:bg-auto
                             rounded-[12px] md:rounded-[0] p-[12px_13px] md:p-0 menu-shadow
                             flex gap-[12px] items-center cursor-pointer hover:opacity-50 " :class="[
                              sidebarExpand ? 'h-[44px]' : ''
                            ]" @click="$router.push(item.to) & sendMetrickByPath(item.to)">
                      <UIIcon :size="mobile.isMobile.value ? (sidebarExpand ? 16 : 24) : 16" :name="item.icon"
                        :color="miColor(item.normalColor, item.activeColor, item.to)">
                      </UIIcon>
                      <div class="font-normal text-base leading-5 "
                        :style="{ color: miColor(item.normalColor, item.activeColor, item.to) }" :class="[
                          miActive(item.to) ? `!font-bold` : ``,
                          mobile.isMobile.value ? 'hidden' : '!block',
                          sidebarExpand ? '!block' : '',

                        ]">
                        {{ item.text }}
                      </div>
                    </div>
                    <template v-if="!mobile.isMobile.value && item.items && miActive(item.to)" class="hidden md:block">
                      <div v-for="subitem in item.items" :key="subitem" class="cursor-pointer hover:opacity-50"
                        @click="$router.push(subitem.to)">
                        <div class="ml-[24px] font-normal text-base leading-5 "
                          :style="{ color: miColor(subitem.normalColor, subitem.activeColor, subitem.to) }">
                          {{ subitem.text }}
                        </div>
                      </div>


                    </template>
                  </template>

                  <div v-else class="md:mt-[16px]" :class="[sidebarExpand ? 'mt-[20px]' : '']"></div>

                </template>


                <div class=" mt-[20px] md:mt-[28px] hidden md:flex gap-[12px] items-center"
                  :class="[sidebarExpand ? '!flex ml-[1rem]' : '']">
                  <div class="text-[#1F1E1E] font-bold text-[1rem] leading-[1.25rem] ">
                    {{ fioStringComputed }}
                  </div>
                </div>
                <div class="hidden md:flex gap-[12px] items-center " :class="[sidebarExpand ? '!flex ml-[1rem]' : '']">
                  <div
                    class="font-normal text-base leading-5 text-[#7B7A7A] max-w-[260px] overflow-hidden text-ellipsis">
                    {{ $authStore.me.email }}
                  </div>
                </div>


                <div class="mt-[20px] md:mt-[28px] w-full md:w-auto h-[48px] md:h-auto bg-white md:bg-auto
                             rounded-[12px] md:rounded-[0] p-[12px_13px] md:p-0 menu-shadow
                             flex gap-[12px] items-center cursor-pointer hover:opacity-50 "
                  @click="popupsStore.openLogoutPopup()">
                  <UIIcon :size="mobile.isMobile.value ? 24 : 16" name="logout" color="#FF3B30"></UIIcon>
                  <div v-if="!mobile.isMobile.value" class="font-normal text-base leading-5 text-[#FF3B30]">
                    Выход
                  </div>
                </div>

                <div v-if="mobile.isMobile.value && sidebarExpand" class="mt-[75px]"></div>

              </div>

              <!--              <div class="flex flex-col gap-[12px]">-->
              <!--                <div class="flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                     @click="$router.push('/employee/lk/personal')">-->
              <!--                  <UIIcon size="16" name="homeDoor" :color="miColor('#7B7A7A', '#0A5CD5', '/employee/lk/personal')"></UIIcon>-->
              <!--                  <div class="font-normal text-base leading-5 " :style="{color: miColor('#7B7A7A', '#0A5CD5', '/employee/lk/personal')}">-->
              <!--                    Главная-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                     @click="$router.push('/employee/lk/feedbacks/responses')"-->
              <!--                >-->
              <!--                  <UIIcon size="16" name="envelope" :color="miColor('#7B7A7A', '#0A5CD5', '/employee/lk/feedbacks/responses')"></UIIcon>-->
              <!--                  <div class="font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Отклики-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                     @click="$router.push('/employee/lk/feedbacks/invations')"-->
              <!--                >-->
              <!--                  <UIIcon size="16" name="personPlus" :color="miColor('#7B7A7A', '#0A5CD5', '/employee/lk/feedbacks/invations')"></UIIcon>-->
              <!--                  <div class="font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Приглашения-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                     @click="$router.push('/employee/lk/resumes')"-->
              <!--                >-->
              <!--                  <UIIcon size="16" name="cardHeading" :color="miColor('#7B7A7A', '#0A5CD5', '/employee/lk/resumes')"></UIIcon>-->
              <!--                  <div class="font-normal text-base leading-5 text-[#7B7A7A]"-->
              <!--                       @click="$router.push({name: 'EmployeeResumesPage'})">-->
              <!--                    Мои резюме-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="cursor-pointer hover:opacity-50">-->
              <!--                  <div class="ml-[24px] font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Опубликованные-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="cursor-pointer hover:opacity-50">-->
              <!--                  <div class="ml-[24px] font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Неопубликованные-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="cursor-pointer hover:opacity-50">-->
              <!--                  <div class="ml-[24px] font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Архив-->
              <!--                  </div>-->
              <!--                </div>-->


              <!--                <div class="mt-[28px] flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                     @click="$router.push('/employee/lk/settings')"-->
              <!--                >-->
              <!--                  <UIIcon size="16" name="gear" :color="miColor('#7B7A7A', '#0A5CD5', '/employee/lk/settings')"></UIIcon>-->
              <!--                  <div class="font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Настройки-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                     @click="$router.push('/employee/lk/support')"-->
              <!--                >-->
              <!--                  <UIIcon size="16" name="questionCircle" :color="miColor('#7B7A7A', '#0A5CD5', '/employee/lk/support')"></UIIcon>-->
              <!--                  <div class="font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                    Поддержка-->
              <!--                  </div>-->
              <!--                </div>-->

              <!--                              <div class="mt-[28px] flex gap-[12px] items-center">-->
              <!--                                <div class="text-[#1F1E1E] font-bold text-[1rem] leading-[1.25rem] ">-->
              <!--                                  {{ fioStringComputed }}-->
              <!--                                </div>-->
              <!--                              </div>-->
              <!--                              <div class="flex gap-[12px] items-center ">-->
              <!--                                <div class="font-normal text-base leading-5 text-[#7B7A7A]">-->
              <!--                                  {{ $authStore.me.email }}-->
              <!--                                </div>-->
              <!--                              </div>-->


              <!--                              <div class="mt-[28px] flex gap-[12px] items-center cursor-pointer hover:opacity-50"-->
              <!--                                   @click="$authStore.logout()">-->
              <!--                                <UIIcon size="16" name="logout" color="#FF3B30"></UIIcon>-->
              <!--                                <div class="font-normal text-base leading-5 text-[#FF3/employer/lk/feedbacks/invations'B30]">-->
              <!--                                  Выход-->
              <!--                                </div>-->
              <!--                              </div>-->


              <!--              </div>-->
            </Sheet>
          </div>
          <div v-if="!sidebarExpand" class="flex-grow">
            <template v-if="loading">
              <div class="w-full h-full flex justify-center items-center">
                <UILoader :is-loading="true"></UILoader>
              </div>
            </template>
            <template v-else>
              <slot></slot>
            </template>

          </div>
        </div>
      </div>
    </UIContainer>

    <!--    <div v-if="$authStore.me"  class="w-full flex flex-col  md:flex-row justify-center md:mt-[50px] mb-[50px] ">-->
    <!--      <div class="p-5 md:p-0 md:mr-[30px]  ">-->
    <!--        <LKCard class="w-full">-->
    <!--          <template v-if="$authStore.me.role === 'employee'" #menu-items>-->
    <!--            <div :class="menuClass('/employee/lk/resumes')" @click="router.push('/employee/lk/resumes')">Резюме</div>-->
    <!--            <div :class="menuClass('/employee/lk/personal')" @click="router.push('/employee/lk/personal')">Личные-->
    <!--              данные-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employee/lk/feedbacks/invations')"-->
    <!--                 @click="router.push('/employee/lk/feedbacks/invations')">Приглашения-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employee/lk/feedbacks/responses')"-->
    <!--                 @click="router.push('/employee/lk/feedbacks/responses')">Отклики-->
    <!--            </div>-->
    <!--          </template>-->
    <!--          <template v-else #menu-items>-->
    <!--            <div :class="menuClass('/employer/lk/vacancies')" @click="router.push('/employer/lk/vacancies')">Вакансии-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employer/lk/feedbacks/invations')"-->
    <!--                 @click="router.push('/employer/lk/feedbacks/invations')">Приглашения-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employer/lk/feedbacks/responses')"-->
    <!--                 @click="router.push('/employer/lk/feedbacks/responses')">Отклики-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employer/lk/personal')" @click="router.push('/employer/lk/personal')">Личные-->
    <!--              данные-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employer/lk/company')" @click="router.push('/employer/lk/company')">Данные-->
    <!--              компании-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employer/lk/tarif')" @click="router.push('/employer/lk/tarif')">Тариф-->
    <!--            </div>-->
    <!--            <div :class="menuClass('/employer/lk/subscriptions')" @click="router.push('/employer/lk/subscriptions')">Подписки-->
    <!--            </div>-->
    <!--          </template>-->
    <!--        </LKCard>-->
    <!--      </div>-->
    <!--      <div class="p-5 md:p-0">-->
    <!--        <slot  name="default">-->
    <!--&lt;!&ndash;          Содержимое странички (LK LAyout)&ndash;&gt;-->
    <!--        </slot>-->
    <!--      </div>-->

    <!--    </div>-->
    <!--    <div v-else class="flex justify-center items-center h-full">-->
    <!--      <UILoader is-loading="true"></UILoader>-->
    <!--    </div>-->


  </DefaultLayout>

</template>

<script setup>
import { ref, defineProps, computed, watch, onBeforeMount } from "vue";
import DefaultLayout from "@/layouts/DefaultLayout";
import LKCard from "@/components/cards/LKCard"
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import UILoader from "@/components/ui/UILoader";
import UIContainer from "@/components/ui/UIContainer";
import Sheet from "@/components/cards/Sheet";
import ButtonNew from "@/components/ui/ButtonNew";
import UIIcon from "@/components/ui/UIIcon";
import { useAuthStore } from "@/stores/authStore";
import { usePopupsStore } from "@/stores/popupsStore";
import UIPageHeader from "@/components/ui/UIPageHeader";
import { useMobile } from "@/composables/useMobile";
import { sendMetrick } from "@/composables/utils";
import { useToast } from "vue-toast-notification";
// import { useRoute } from "vue-router";



const toast = useToast()

const authStore = useAuthStore()

const role = authStore.role


const toCreateVacancy = () => {
  if (role !== 'employer') {
    router.push('/')
    toast.error('Вы не являетесь работодателем, действие невозможно')
  }
  if (authStore.me.moderation_vacancies_ban_at !== null) {
    toast.error('Вы заблокированны на создание вакансий')
  } else {
    router.push('/employer/lk/vacancies/create') & sendMetrick('EMPLOYER-LK-ADD-VACANCY')
  }

}

const toCreateInternship = () => {
  if (role !== 'employer') {
    router.push('/')
    toast.error('Вы не являетесь работодателем, действие невозможно')
  }
  if (authStore.me.moderation_vacancies_ban_at !== null) {
    toast.error('Вы заблокированны на создание стажировок')
  } else {
    router.push('/employer/lk/internships/create')
  }

}

const toCreateResume = () => {
  if (role !== 'employee') {
    router.push('/')
    toast.error('Вы не являетесь соискателем, действие невозможно')
  }
  if (authStore.me.moderation_resumes_ban_at !== null) {
    toast.error('Вы заблокированны на создание вакансий')
  } else {
    router.push('/employee/lk/resumes/create')
  }

}

onBeforeMount(() => {
  // authStore.getMe(true)
})

const props = defineProps({
  loading: {
    default: false,
  }
})

const sendMetrickByPath = (link) => {
  if (link === '/employer/account/support')
    sendMetrick('EMPLOYER-LK-SUPPORT')
  else if (link === '/employer/lk/vacancies')
    sendMetrick('EMPLOYER-LK-MY-VACANCIES')
  // console.log(route.fullPath)
  else if (link === 'employer/lk/feedbacks/responses')
    sendMetrick('EMPLOYER-LK-RESPONSES')
  else if (link === '/employer/lk/tarif')
    sendMetrick('EMPLOYER-LK-TARIF')
  else if (link === '/account/articles')
    if (authStore.me.role === 'employer')
      sendMetrick('EMPLOYER-LK-ARTICLES')
    else if (authStore.me.role === 'employee')
      sendMetrick('EMPLOYEE-LK-ARTICLES')
    else if (link === '/account/employer/events')
      sendMetrick('EMPLOYER-LK-EVENTS')
    else if (link === 'employee/lk/feedbacks/responses')
      sendMetrick('EMPLOYEE-LK-RESPONSES')
    else if (link === '/employee/lk/resumes')
      sendMetrick('EMPLOYEE-LK-RESUMES')
}


const mobile = useMobile()

const popupsStore = usePopupsStore()

const miActive = (path) => {
  if (route.fullPath.includes(path)) {
    return true
  }
  return false
}


watch(() => mobile.isMobile.value, (v) => {
  if (!v) {
    sidebarExpand.value = false
  }
})


const sidebarExpand = ref(false)

const titleByPage = computed(() => {
  const path = route.path
  if (path.includes('/employee/lk/main')) {
    return 'Личный кабинет'
  } else if (path.includes('lk/feedbacks/responses')) {
    return 'Отклики'
  } else if (path.includes('lk/vacancies')) {
    return 'Мои вакансии'
  }
  // else if (path.includes('/employer/lk/feedbacks/responses')) {
  //   return 'Отклики'
  // }
  else if (path.includes('lk/feedbacks/invations')) {
    return 'Приглашения'
  } else if (path.includes('/employee/lk/resumes/create')) {
    return 'Создание резюме'
  } else if (path.includes('/employee/lk/resumes/')) {
    return 'Редактирование резюме'
  } else if (path.includes('/employee/lk/resumes')) {
    return 'Мои резюме'
  } else if (path.includes('/account/settings')) {
    return 'Настройки'
  } else if (path.includes('/account/support')) {
    return 'Поддержка'
  } else if (path.includes('/account/articles/create')) {
    return 'Создание статьи'
  }

  return 'Личный кабинет'
})

const miColor = (normal, active, path) => {
  if (miActive(path)) {
    return active
  }
  return normal
}

const route = useRoute();
const router = useRouter();

const disableScroll = ref(false);
const activeItem = ref(null);



const fioStringComputed = computed(() => {
  return `${authStore.me.first_name || ' '} ${authStore.me.last_name || ' '}`
})

// const handleToggleScroll = () => {
//   disableScroll.value = !disableScroll.value;
// };


// const menuClass = (menuItemPath) => {
//   return router.currentRoute.value.path === menuItemPath ? 'form-label-active' : 'form-label';
// };
const menu = ref(null)

if (role === 'employer') {
  menu.value = [
    {
      to: `/employer/lk/main`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Главная',
      icon: 'homeDoor',
    },
    {
      to: `/employer/lk/feedbacks/responses`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Отклики',
      icon: 'envelope',
      items: [
        {
          to: `/${role}/lk/feedbacks/responses?status=NEW`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Новые',
        },
        {
          to: `/${role}/lk/feedbacks/responses?status=ACCEPTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Принятые',
        },
        {
          to: `/${role}/lk/feedbacks/responses?status=REJECTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Отклоненные',
        },
        {
          to: `/${role}/lk/feedbacks/responses?status=ARCHIVE`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    {
      to: `/${role}/lk/feedbacks/invations`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Приглашения',
      icon: 'personPlus',
      items: [
        {
          to: `/${role}/lk/feedbacks/invations?status=NEW`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Новые',
        },
        {
          to: `/${role}/lk/feedbacks/invations?status=ACCEPTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Принятые',
        },
        {
          to: `/${role}/lk/feedbacks/invations?status=REJECTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Отклоненные',
        },
        {
          to: `/${role}/lk/feedbacks/invations?status=ARCHIVE`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    {
      to: '/employer/lk/vacancies',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Мои вакансии',
      icon: 'cardHeading',
      items: [
        {
          to: '/employer/lk/vacancies?status=PUBLISHED',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Опубликованные',
        },
        {
          to: '/employer/lk/vacancies?status=DRAFT',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Неопубликованные',
        },
        {
          to: '/employer/lk/vacancies?status=ARCHIVE',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    {
      to: '/employer/lk/internships',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Стажировки',
      icon: 'hatEducation',
      items: [
        {
          to: '/employer/lk/internships?status=PUBLISHED',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Опубликованные',
        },
        {
          to: '/employer/lk/internships?status=DRAFT',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Неопубликованные',
        },
        {
          to: '/employer/lk/internships?status=ARCHIVE',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    {
      to: '/employer/lk/tarif',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Тарифы и оплата',
      icon: 'money',
      items: [
        {
          to: '/employer/lk/tarif?type=tarif',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Тариф',
        },
        {
          to: '/employer/lk/tarif?type=acts',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Счета и акты',
        },
        {
          to: '/employer/lk/tarif?type=paid',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Оплата',
        },
        {
          to: '/employer/lk/tarif?type=promocodes',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Промокоды',
        },
      ]
    },
    null, // Разделитель в 28px
    {
      to: '/account/articles',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Статьи',
      icon: 'pencil',
    },
    {
      to: '/account/employer/events',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Мероприятия',
      icon: 'calendarFrame',
    },
    null,
    {
      to: '/employer/account/settings',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Настройки',
      icon: 'gear',
      items: [
        {
          to: '/employer/account/settings?type=personalData',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Данные компании',
        },
        {
          to: '/employer/account/settings?type=details',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Реквизиты',
        },
        {
          to: '/employer/account/settings?type=loginPasswords',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Безопасность',
        },
      ],
    },
    {
      to: '/employer/account/support',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Поддержка',
      icon: 'questionCircle',
    },
  ]
} else if (role === 'employee') {
  menu.value = [
    {
      to: `/employee/lk/main`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Главная',
      icon: 'homeDoor',
    },
    {
      to: `/${role}/lk/feedbacks/responses`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Отклики',
      icon: 'envelope',
      items: [
        {
          to: `/${role}/lk/feedbacks/responses?status=NEW`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Новые',
        },
        {
          to: `/${role}/lk/feedbacks/responses?status=ACCEPTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Принятые',
        },
        {
          to: `/${role}/lk/feedbacks/responses?status=REJECTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Отклоненные',
        },
        {
          to: `/${role}/lk/feedbacks/responses?status=ARCHIVE`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    {
      to: `/${role}/lk/feedbacks/invations`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Приглашения',
      icon: 'personPlus',
      items: [
        {
          to: `/${role}/lk/feedbacks/invations?status=NEW`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Новые',
        },
        {
          to: `/${role}/lk/feedbacks/invations?status=ACCEPTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Принятые',
        },
        {
          to: `/${role}/lk/feedbacks/invations?status=REJECTED`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Отклоненные',
        },
        {
          to: `/${role}/lk/feedbacks/invations?status=ARCHIVE`,
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    {
      to: '/employee/lk/resumes',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Мои резюме',
      icon: 'cardHeading',
      items: [
        {
          to: '/employee/lk/resumes?status=PUBLISHED',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Опубликованные',
        },
        {
          to: '/employee/lk/resumes?status=NO_PUBLISHED',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Неопубликованные',
        },
        {
          to: '/employee/lk/resumes?status=ARCHIVE',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Архив',
        },
      ],
    },
    null, // Разделитель в 28px
    {
      to: '/account/articles',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Статьи',
      icon: 'pencil',
    },
    // {
    //   to: '/account/employer/events',
    //   normalColor: '#7B7A7A',
    //   activeColor: '#0A5CD5',
    //   text: 'Мероприятия',
    //   icon: 'calendarFrame',
    // },
    null, // Разделитель в 28px
    {
      to: '/employee/account/settings',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Настройки',
      icon: 'gear',
      items: [
        {
          to: '/employee/account/settings?type=personalData',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Персональные данные',
        },
        {
          to: '/employee/account/settings?type=loginPasswords',
          normalColor: '#7B7A7A',
          activeColor: '#1F1E1E',
          text: 'Безопасность',
        },
      ],
    },
    {
      to: '/employee/account/support',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Поддержка',
      icon: 'questionCircle',
    },
  ]
} else if (role === 'accountant') {
  menu.value = [
    {
      to: `/accountant/lk/orders`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Счета и акты',
      icon: 'money',
    },
  ]
} else if (role === 'moderator') {
  menu.value = [
    {
      to: `/moderator/vacancies`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Вакансии',
      icon: 'cardHeading',
    },
    {
      to: `/moderator/resumes`,
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Резюме',
      icon: 'personPlus',
    },
    {
      to: '/moderator/articles',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Статьи',
      icon: 'pencil',
    },
    {
      to: '/moderator/events',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Мероприятия',
      icon: 'calendarFrame',
    },
    {
      to: '/moderator/users',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Пользователи',
      icon: 'personPlus',
    },
    null,
    {
      to: '/moderator/settings',
      normalColor: '#7B7A7A',
      activeColor: '#0A5CD5',
      text: 'Настройки',
      icon: 'gear',
    },
  ]
}


</script>

<style>
.form-label-active {


  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4A4A4A;
  cursor: pointer;
  margin-bottom: 15px;
}

.form-label {


  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  /* identical to box height, or 122% */
  display: flex;
  align-items: center;
  letter-spacing: 0.035em;
  color: black;
  cursor: pointer;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .menu-shadow {
    box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
    border: 1px solid transparent;
  }
}
</style>