<template>
  <div class="fixed top-0 z-40 w-full max-h-full flex flex-col h-full pointer-events-none overflow-hidden">

    <div v-if="isMobile"
         class="pointer-events-auto flex flex-row header-color h-[56px] pr-[1rem] pl-[1rem]  justify-between items-center">
         <router-link v-if="route.path === '/'" to="/">
          <img src="./../../assets/images/logo.webp" class="w-[108px] h-[44px] object-cover"/>
        </router-link>
        <router-link v-else to="/">
          <img src="./../../assets/images/Logo_2_.webp" class="w-[108px] h-[44px] object-cover"/>
        </router-link>
      <div class="flex flex-row items-center gap-x-[1rem]" v-if="route.path === '/'">
        <UIIcon size="24" color="white" name="lupa"></UIIcon>
        <UIIcon size="24" @click="toggleMenu" name="mobileMenu"></UIIcon>
      </div>
      <div class="flex flex-row items-center gap-x-[1rem]" v-else>
        <UIIcon size="24" color="#0A5CD5" name="lupa"></UIIcon>
        <UIIcon size="24" color="#0A5CD5" @click="toggleMenu" name="mobileMenu"></UIIcon>
      </div>


    </div>
    <div v-else class="pointer-events-auto flex flex-row bg-white h-[60px] justify-center items-center header-color">
      <div class="flex flex-row justify-between items-center w-[992px]">
        <router-link v-if="route.path === '/'" to="/">
          <img src="./../../assets/images/logo.webp" class="w-[70px] h-[28px] object-cover"/>
        </router-link>
        <router-link v-else to="/">
          <img src="./../../assets/images/Logo_2_.webp" class="w-[70px] h-[28px] object-cover"/>
        </router-link>
        

        <div class="flex flex-row gap-x-[24px]" :class="headerMenuItemColor">
          <router-link to="/employee/vacancies" @click="() =>sendMetrick('EMPLOYEE-HEADER')">
            <div class="header-menu-item">
              Соискателям
            </div>
          </router-link>

          <router-link to="/employer/resumes" @click="sendMetrick('EMPLOYER-HEADER')">
            <div class="header-menu-item">
              Работодателям
            </div>
          </router-link>

          <router-link to="/students">
            <div class="header-menu-item" @click="sendMetrick('STUDENTS-HEADER')">
              Студентам
            </div>
          </router-link>

          <router-link to="/education/articles" @click="sendMetrick('EDUCATION-HEADER')">
            <div class="header-menu-item">
              Обучающая платформа
              <div v-if="!isMobile" class="fixed top-[75px] ml-[165px]">
                <Tooltip v-if="showEmployeeTooltip" type="employee"></Tooltip>
                <Tooltip v-if="showEmployerTooltip" type="employer"></Tooltip>
              </div>
            </div>
          </router-link>


        </div>

        <div v-if="$authStore.isAuthenticated" class="flex flex-row gap-[10px]">
          <!-- <div v-if="$authStore.role === 'employee'">
            <router-link :to="{ path: '/employee/lk/main' }">
              <img v-if="$authStore.me.employer_profile?.avatar || $authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.employer_profile?.avatar || $authStore.me.avatar}/download`" class="w-[44px] h-[44px] rounded-[22px] border border-gray-400">
              <Icon v-else name="employee-icon"></Icon>
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'employer'">
            <router-link :to="{ path: '/employer/lk/main' }">
              <img v-if="$authStore.me.employer_profile?.avatar || $authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.employer_profile?.avatar || $authStore.me.avatar}/download`" class="w-[44px] h-[44px] rounded-[22px] border border-gray-400">
              <Icon v-else name="employer-icon"></Icon>
            </router-link>
          </div> -->
          <div v-if="$authStore.role === 'employee'">
            <router-link :to="{ path: '/employee/lk/main' }">
              <Avatar :imageId="$authStore.me?.avatar" size="44"></Avatar>
              <!-- <img v-if="$authStore.me.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.avatar}/download`" class="w-[44px] h-[44px] rounded-[22px] border border-gray-400">
              <Icon v-else name="employer-icon"></Icon> -->
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'employer'">
            <router-link :to="{ path: '/employer/lk/main' }">
              <Avatar :imageId="$authStore.me?.employer_profile?.avatar" size="44"></Avatar>
              <!-- <img v-if="$authStore.me.employer_profile?.avatar" :src="`${fetch.baseURL}/files/${$authStore.me.employer_profile?.avatar}/download`" class="w-[44px] h-[44px] rounded-[22px] border border-gray-400">
              <Icon v-else name="employer-icon"></Icon> -->
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'accountant'">
            <router-link :to="{ path: '/accountant/lk/orders' }">
              <Icon name="employer-icon"></Icon>
            </router-link>
          </div>
          <div v-else-if="$authStore.role === 'moderator'">
            <router-link :to="{ path: '/moderator/vacancies' }">
              <Icon name="employer-icon"></Icon>
            </router-link>
          </div>


        </div>


        <div v-else-if="route.path !== '/'" class="flex flex-row gap-x-[24px]">
          <UIIcon size="32" name="lupa" color="#0A5CD5"></UIIcon>
          <ButtonNew type="stroke" class="!text-[13px] !bg-transparent !h-[32px] !w-[100px] !rounded-[32px] !text-[#0A5CD5] hover:font-bold" @click="router.push('/login')">Вход</ButtonNew>
        </div>
        <div v-else-if="route.path === '/'" class="flex flex-row gap-x-[24px]">
          <UIIcon size="32" name="lupa" color="white"></UIIcon>
          <ButtonNew type="" class="!text-[13px] !bg-transparent !h-[32px] !w-[100px] !rounded-[32px] !border !border-white !text-white hover:font-bold" @click="router.push('/login')">Вход</ButtonNew>
        </div>

      </div>


    </div>
    <div v-if="menu"
         class="flex flex-col w-full h-[550px] bg-white items-center rounded-b-[16px] pointer-events-auto z-20 ">
      <div class="h-[73px] w-[90%] flex flex-row items-end justify-start">
        <Icon class="" name="man-icon"></Icon>
        <div class="w-full text-center text-[20px] font-bold">Пользователь</div>
        <div @click="toggleMenu" class="mb-[1.7rem]">
          <Icon name="kross"></Icon>
        </div>
      </div>
      <div class="flex flex-col w-[90%] items-start mt-[32px] mb-[36px]">
        <div @click="router.push('/employee/vacancies') & sendMetrick('EMPLOYEE-HEADER') & toggleMenu()" class="text-[20px] mb-[28px] leading-[40px]">Соискателям</div>
        <div @click="router.push('/employer/resumes') & sendMetrick('EMPLOYER-HEADER') & toggleMenu()" class="text-[20px] mb-[28px] leading-[40px]">Работодателям</div>
        <div @click="router.push('/students') & sendMetrick('STUDENTS-HEADER') & toggleMenu()" class="text-[20px] mb-[28px] leading-[40px]">Студентам</div>
        <div @click="router.push('/education/articles') & sendMetrick('EDUCATION-HEADER') & toggleMenu()" class="text-[20px] mb-[28px] leading-[40px]">Обучающая платформа</div>
      </div>
      <div v-if="!$authStore.isAuthenticated" class="flex flex-col w-[90%] gap-y-[16px] mb-[29px]">
        <ButtonNew @click="router.push('/login') & sendMetrick('LOGIN-AS-EMPLOYEE-HEADER') & toggleMenu()" type="stroke">Войти как соискатель</ButtonNew>
        <ButtonNew @click="router.push('/login') & sendMetrick('LOGIN-AS-EMPLOYER-HEADER') & toggleMenu()">Войти как работодатель</ButtonNew>
      </div>
      <div v-else class="flex flex-col w-[90%] gap-y-[16px] mb-[29px]">
        <ButtonNew type="stroke" @click="toAccount & toggleMenu()">Личный кабинет</ButtonNew>

        <ButtonNew @click="$authStore.logout()" >Выйти</ButtonNew>
      </div>


    </div>
    <div v-if="menu" class="absolute inset-0 bg-opacity-20 bg-[#3D7CD1] overflow-hidden pointer-events-auto"></div>


  </div>

</template>

<script setup>
import {computed} from 'vue';
import Icon from './Icon.vue';
import {defineProps, defineEmits, ref, watch, onUnmounted, onMounted} from 'vue';
import {useRouter, useRoute} from 'vue-router'
import Button from "@/components/ui/Button";
import ButtonNew from './ButtonNew.vue';
import {useAuthStore} from "@/stores/authStore";
import Tooltip from './Tooltip.vue';
import {useFetch} from "@/composables/useFetch";

import { sendMetrick } from '@/composables/utils';
import UIIcon from './UIIcon.vue';
import Avatar from './Avatar.vue';

const authStore = useAuthStore()

const showEmployeeTooltip = ref(false)
const showEmployerTooltip = ref(false)
const router = useRouter()
const route = useRoute();

const fetch = useFetch()

const handleMouseEnter = (type) => {
  if (type === 'employee') {
    showEmployeeTooltip.value = true
  } else if (type === 'employer') {
    showEmployerTooltip.value = true
  }
}

const toAccount = () => {
  if (authStore.me.role === 'employer') {
    router.push('/employee/lk/main')
  }
  else if (authStore.me.role === 'employee') {
    router.push('/employee/lk/main')
  }
}

const handleMouseLeave = (type) => {
  if (type === 'employee') {
    showEmployeeTooltip.value = false
  } else if (type === 'employer') {
    showEmployerTooltip.value = false
  }
}

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  const app = document.getElementById('app')
  app.style.overflow = 'auto'
  window.addEventListener('resize', updateIsMobile);
});


onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});

const standardClass = "absolute top-0 w-full z-10 mb-15 ";
const props = defineProps({
  class: {
    type: String,
    default: ''
  },
});

const computedClasses = computed(() => {
  return standardClass + props.class;
});

const headerMenuItemColor = computed(() => {
  if (route.path === '/') {
    return 'text-white'
  }
  else {
    return 'text-[#0A5CD5]'
  }
});

const menu = ref(false);
const emit = defineEmits(['toggleScroll', 'showMenu', 'hideMenu']);
const toggleMenu = () => {

  const app = document.getElementById('app')
  // emit('toggleScroll')
  menu.value = !menu.value;
  if (menu.value) {
    // document.body.style.overflow = 'hidden';
    app.style.overflow = 'hidden'
    // emit('showMenu')
    // document.documentElement.style.overflow = 'hidden';
  } else {
    // document.body.style.overflow = 'auto';
    app.style.overflow = 'auto'
    // emit('hideMenu')
    // document.documentElement.style.overflow = 'auto';
  }

};

const run = () => {

  const app = document.getElementById('app')
  app.style.overflow = 'auto'
};

</script>

<style scoped>
.header-menu-item {
  @apply font-bold md:font-normal text-[1rem] md:text-[14px] leading-[1.1rem] md:leading-[17.2px] cursor-pointer;
}

.header-color {
  background-color: rgba(255, 255, 255, 0.12); /* Полупрозрачный белый фон */
  backdrop-filter: blur(4px); /* Применение размытия на 12 пикселей */
}
</style>