<template>
  <DefaultLayout v-if="response">
    <UIContainer class="flex flex-col justify-center items-center ">
      <div class="flef flex-col gap-2 hidden md:block">
        <div class=" text-[#0A5CD5] text-[2.25rem] leading-[2.75rem] font-bold">Отклик</div>
        <div class="mt-[20px] mb-[32px] flex items-center gap-[12px] cursor-pointer" @click="$router.back()">
          <img src="/icons/arrow-left-20-black.svg">
          Вернуться к полному списку
        </div>
      </div>

      <Sheet v-if="response.text !== null && response.text !== ''" class="!p-[32px] mb-[24px]">
        <div class="text-[12px] font-light mb-[12px]">Опубликовано {{ formatDateTimeResumeCreated(response.created_at)
        }}</div>
        <div class="md:text-[24px] text-[18px] mb-[24px]">Сопроводительное письмо</div>
        <div class="text-[14px]">
          <span v-html="computedResponseText.replace(/\n/g, '<br>')"></span>
        </div>
        <div class="flex flex-row-reverse">
          <div class="text-[#0B54BF] cursor-pointer text-[14px] font-medium mt-[12px]" @click="fullText = !fullText">{{
            computedButtonLabel }}</div>
        </div>

      </Sheet>

      <ResumeFullCard v-if="resume" :id=resume.id :imageSrcId=resume.user_avatar_id :bornDate="resume.born_date"
        :publicationTime=resume.created_at :employeeFirstName=resume.first_name :employeeLastName=resume.last_name
        :contacts="resume.contacts" :citizenship=resume.citizenship_name :specialityName=resume.speciality_name
        :employmentType=resume.employment_type :employmentShedule=resume.employment_schedule
        :city=resume.city_of_residence :readyToMove=resume.ready_to_move :experience=resume.work_experience
        :age=resume.full_years :price=resume.salary_expectations :currencyName=resume.currency_display_symbol
        :experienceUnits=resume.experience :about=resume.about_me :educationUnits=resume.education :resume="resume">
        <template #under-logo>
          <ButtonNew v-if="response.status === 'NEW' && authStore.role === 'employer'"
            @click="setStatusEmployer(response.id, 'ACCEPTED')">Пригласить</ButtonNew>
        </template>
      </ResumeFullCard>
      <Sheet v-else class="md:p-[24px]">
        <div class="flex flex-row justify-between">
          <div class="flex flex-col">
            <div
              class="hidden md:flex text-black font-medium text-[1.875rem] leading-[2.3125rem] whitespace-nowrap items-center gap-[32px]">
              Общая информация</div>
            <div
              class="md:mt-[31px] font-bold text-lg md:text-[2.25rem] leading-6 md:leading-[2.75rem] text-black md:text-[#0A5CD5] flex-none order-0 self-stretch flex-grow-0">
              {{ response.user_name }}
            </div>
            <div class="mt-[32px] mb-[16px] flex flex-row items-center gap-[12px]">
              <UIIcon name="geoAlt" size="24"></UIIcon>
              {{ response.user_city }}
            </div>
            <div class="flex items-center gap-[16px]">
              <img src="/icons/envelope-12-blue.svg" class="w-[24px] h-[24px]">
              <div v-if="response.user_email"
                class="flex items-center h-[33px] text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                {{ response.user_email }}
              </div>
            </div>
            <div class="flex items-center gap-[12px]">
              <img src="/icons/telephone-12-blue.svg" class="w-[24px] h-[24px]">
              <div v-if="response.user_phone"
                class="flex items-center h-[33px] text-[0.875rem] leading-[1.0625rem] text-[#1F1E1E]">
                {{ response.user_phone }}
              </div>
            </div>
          </div>

          <div class="flex flex-col justify-between">
            <img :src="response.user_avatar_id ? imageSrc : '/images/UserEmpty.webp'" alt="Аватар"
              class="w-[134px] md:w-[232px] h-[134px] md:h-[232px] rounded-full flex-none mb-[24px]" />
            <ButtonNew v-if="response.status === 'NEW' && authStore.role === 'employer'"
              @click="setStatusEmployer(response.id, 'ACCEPTED')">Пригласить
            </ButtonNew>
          </div>

        </div>

      </Sheet>
      <div v-if="vacancy" class="mt-[30px]">
        <VacancyCardFull :vacancy="vacancy">
          <template #under-logo>
            <div></div>
            <!-- <ButtonNew v-if="response.status === 'NEW' && authStore.role === 'employee'"
              @click="setStatusEmployee(response.id, 'ACCEPTED')">Откликнуться</ButtonNew> -->
          </template>
        </VacancyCardFull>
      </div>
      <div class="mb-[56px]"></div>

    </UIContainer>

  </DefaultLayout>
</template>
<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import ResumeFullCard from '@/components/cards/ResumeFullCard.vue';
import { onMounted, ref, computed } from 'vue';
import { useFetch } from '@/composables/useFetch';
import { useAuthStore } from '@/stores/authStore';
import { useRoute, useRouter } from 'vue-router';
import UIContainer from '@/components/ui/UIContainer.vue';
import Sheet from '@/components/cards/Sheet.vue';
import { useUtils } from '@/composables/useUtils';
import { useToast } from 'vue-toast-notification';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import {

  formatDateTimeResumeCreated,

} from '@/composables/utils';
import UIIcon from '@/components/ui/UIIcon.vue';
import VacancyCardFull from '@/components/cards/VacancyCardFull.vue';
const toast = useToast()
const utils = useUtils()
const router = useRouter();


const route = useRoute();
const authStore = useAuthStore();
const { baseURL, authRequest } = useFetch();

const response = ref(null)
const resume = ref(null)
const vacancy = ref(null)
const fullText = ref(null)

const computedResponseText = computed(() => {
  if (fullText.value === null || fullText.value === true)
    return response.value.text
  else if (response.value.text !== null)
    return response.value.text.substring(0, 110);
  else
    return null
})

const computedButtonLabel = computed(() => {
  if (fullText.value === true)
    return 'Свернуть'
  else if (fullText.value === false)
    return 'Развернуть'
  else
    return null
})

const imageSrc = computed(() => {
  if (response.value.user_avatar_id) {
    return `${baseURL}/files/${response.value.user_avatar_id}/download`
  } else return ''
})

const setStatusEmployer = (responseId, status) => {
  return authRequest(`/account/employer/responses/${responseId}/`, {
    method: 'PATCH',
    body: {
      status: status,
    }
  }).then((data) => {
    router.back();
    toast.success('Отклик принят')
  })
}

const setStatusEmployee = (responseId, status) => {
  return authRequest(`/account/employee/responses/${responseId}/`, {
    method: 'PATCH',
    body: {
      status: status,
    }
  }).then((data) => {
    router.back();
    toast.success('Отклик принят')
  })
}



onMounted(() => {
  authStore.getMe()
  if (authStore.role === 'employee') {
    authRequest(`account/employee/responses/${route.params.id}/`, {
      method: 'GET',
    }).then((data) => {
      response.value = data
      resume.value = data.resume
      vacancy.value = data.vacancy
      console.log('fasfasf', resume.value)

      if (response.value.text)
        if (response.value.text.length > 110)
          fullText.value = false
    })
  } else if (authStore.role === 'employer') {
    authRequest(`account/employer/responses/${route.params.id}/`, {
      method: 'GET',
    }).then((data) => {
      response.value = data
      resume.value = data.resume
      vacancy.value = data.vacancy
      console.log('fasfasf', resume.value)

      if (response.value.text)
        if (response.value.text.length > 110)
          fullText.value = false
    })

  }




});
</script>
<style scoped></style>