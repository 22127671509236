<template>
  <popup @close="emit('update:modelValue', false)" v-if="fetched">
    <div>

      <div
        class="mt-[32px] md:mt-0 mb-[12px] font-medium text-[1.125rem] md:text-[1.2rem] leading-[1.375rem] text-center md:text-left md:leading-[1.8125rem] text-black">
        Сопроводительное письмо выделит вас среди работодателей
      </div>
      <UITextArea v-model="popupsStore.invitation.message" maxCharacters="2000" :errorText="errorText"
                  placeholder="Напишите соискателю, почему вы хотите пригласить его"
                  textAreaClass="font-light text-xs leading-4 text-gray-500 md:min-h-[110px] min-h-[70px] md:max-h-[55vh] max-h-[40vh] "
      ></UITextArea>
      <div class="flex md:flex-row flex-col justify-center mt-[40px] gap-3">
        <div class="flex flex-col md:w-[50%] w-full items-center flex-shrink-0">
          <UISelectNew :options="vacancies" placeholder="Выберите вакансию" triggerClass="md:h-[48px] h-[40px]"
          v-model="vacancy" ></UISelectNew>
          <div class="text-[12px] mt-2 text-[#FF0000]">{{ errorNoVacancy }}</div>
        </div>
        
        <div class="flex flex-col md:flex-row gap-[16px] w-full">
          <ButtonNew v-if="!errorText && !errorNoVacancy" class="w-fit p-[14px_16px] md:h-[48px] h-[40px]" @click="send()">Пригласить</ButtonNew>
          <ButtonNew v-else type="stroke-disabled" class="w-fit p-[14px_16px] md:h-[48px] h-[40px]">Пригласить</ButtonNew>
        </div>


      </div>
    </div>
  </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import {defineProps, defineEmits, ref, watch, onBeforeMount, computed} from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useAuthStore} from "@/stores/authStore";
import UISelectNew from "../ui/UISelectNew.vue";
import { useFetch } from "@/composables/useFetch";
const fetch = useFetch();

const authStore = useAuthStore()

const popupsStore = usePopupsStore()

const errorText = ref(null)
const vacancies = ref(null)
const fetched = ref(false)
const vacancy = ref(null)

const errorNoVacancy = computed(() => {
  if (vacancy.value === null)
    return 'Выберите вакансию'
  else
    return null
});

watch(() => popupsStore.invitation.message, (value) => {
  if (value.length > 2000) {
    errorText.value = 'Превышено допустимое количество символов'
  } else errorText.value = null

})

onBeforeMount(() => {
  fetch.authRequest('/account/vacancies/', {
    method: 'GET',
    params: {
      status__in: 'PUBLISHED',
    }
  }).then((data) => {
    vacancies.value = data.results.map(obj => ({ title: obj.name, value: obj.id }))

    // console.log('reassdfadf', resumes.value.length)
    if (vacancies.value.length === 1) {

      vacancy.value = resumes.value[0].value
      // console.log('reassdfad123123123412', resume.value)
    }
    fetched.value = true

  })
})

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])

const send = () => {
  if (popupsStore.invitation.callback) {
    popupsStore.invitation.vacancy = vacancy.value
    popupsStore.invitation.callback()
    popupsStore.invitation.visible = false
  }
}
</script>

<style scoped>

</style>