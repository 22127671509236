<template>
  <DefaultLayout>
    <div class="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div class="text-center">
        <h1 class="text-4xl font-bold text-blue-600 mb-4">Эта страница в разработке</h1>
        <p class="text-lg text-gray-700 mb-6">Мы работаем над чем-то замечательным. Пожалуйста, вернитесь позже!</p>
        <div class="flex justify-center mb-4">
          <img src="@/assets/images/under-construction.svg" alt="В разработке" class="w-1/2 max-w-xs" />
        </div>
        <div class="flex flex-col gap-y-[12px] items-center">
          <ButtonNew class="max-w-[300px]" @click="goHome">На главную</ButtonNew>
          <SupportCardNew></SupportCardNew>

          <!-- <ButtonNew class=" bg-gray-300 text-gray-800 hover:bg-gray-400 transition" @click="contactUs">Связаться с нами</ButtonNew> -->
          
        </div>

        

        
      </div>
    </div>
  </DefaultLayout>
</template>

<script setup>
import { useRouter } from 'vue-router';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import SupportCardNew from '@/components/cards/SupportCardNew.vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';

const router = useRouter();

const goHome = () => {
  router.push('/');
};

const contactUs = () => {
  // Здесь можно добавить логику для открытия формы обратной связи или перехода на страницу контактов
  alert('Свяжитесь с нами по адресу: support@example.com');
};
</script>

<style scoped>
/* Дополнительные стили, если необходимо */
</style>
