<template>
  <popup @close="emit('update:modelValue', false)" :class="[
    mobile.isMobile.value ? '' : '!w-[600px]'
  ]">
    <div >

      <div class=" mt-[32px] md:mt-0 mb-[12px] font-medium text-[1.125rem] md:text-[1.5rem] leading-[1.375rem] text-center  md:leading-[1.8125rem] text-black">
        {{ popupsStore.info.title }}
      </div>
      <div class="text-center" v-html="popupsStore.info.message">
      </div>

      <div class="flex justify-end">
        <ButtonNew type="stroke" class="mt-[40px] whitespace-nowrap p-[14px_16px] !w-[50%]" @click="okay()">Понятно</ButtonNew>




      </div>
    </div>
  </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import {defineProps, defineEmits} from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useAuthStore} from "@/stores/authStore";
import {useMobile} from "@/composables/useMobile";

const authStore = useAuthStore()

const mobile = useMobile()
const popupsStore = usePopupsStore()

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])

const okay = () => {
  if (popupsStore.info.callback) {
    popupsStore.info.callback()

  }

}
</script>

<style scoped>

</style>