<template>

  <img v-if="imageId" :src="avatarSrc" class="rounded-[1000px] border-[1px] border-[$99999955]" :style="imageStyles">
  <UIIcon v-else :size="size" name="camera" color="#E7EFFB"></UIIcon>
  
</template>

<script setup>
import UIIcon from "@/components/ui/UIIcon";
import {computed} from "vue";
import {useFetch} from "@/composables/useFetch";

const fetch = useFetch()

const props = defineProps({
  imageId: {
    default: null,
  },
  size: {
    default: '24'
  }

})

const avatarSrc = computed(() => {
  const src = props.imageId ? `${fetch.baseURL}/files/${props.imageId}/download` : '/images/UserEmpty.webp'
  return src
})

const imageStyles = computed(() => {
  const styles = {}

  styles.width = `${props.size}px`
  styles.height = `${props.size}px`

  return styles
})

</script>

<style scoped>

</style>