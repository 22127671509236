<template>
  <LKLayout>
    <div class="p-[12px] flex gap-[40px]">
      <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
        :class="`${miActive('/employee/account/settings?type=personalData') ? '!text-[#0A5CD5] !font-medium' : ''}`"
        @click="$router.push('/employee/account/settings?type=personalData')">
        Личные данные
      </div>
      <div class="text-[#7B7A7A] text-[1.25rem] leading-[1.5rem] font-normal hover:opacity-50 cursor-pointer"
        :class="`${miActive('/employee/account/settings?type=loginPasswords') ? '!text-[#0A5CD5] !font-medium' : ''}`"
        @click="$router.push('/employee/account/settings?type=loginPasswords')">
        Безопасность
      </div>
    </div>
    <div v-if="fetched">
      <Sheet v-if="miActive('/employee/account/settings?type=personalData')" class="md:p-[32px]">

        <div class="flex flex-row">

          <div class="md:!w-[231px] md:!h-[308px] !w-[284px] !h-[200px] relative cursor-pointer overflow-hidden" @click="changeAvatar">
            <template v-if="authStore.me.avatar">
              <!--                <img :src="authStore.me.avatar" class="w-full h-full object-cover absolute left-0 top-0">-->
              <Avatar :image-id="authStore.me.avatar" :size="mobile.isMobile.value ? 50 : 231"></Avatar>
              <div
                class="absolute bottom-0 left-0 w-full h-[46px] text-center  flex gap-[8px] items-center justify-center">
                <div class="text-[#7B7A7A] text-base leading-5 font-normal">Редактировать</div>
                <div>
                  <UIIcon size="24" name="camera" color="#7B7A7A"></UIIcon>
                </div>
              </div>
            </template>
            <template v-else>
              <img src="/images/camera.svg" class="absolute top-[86px] left-[66px]">
              <div class="absolute bottom-0 mb-[34px] text-center">
                <div class="text-[#0A5CD5] font-light text-[0.75rem] leading-[0.9375rem]">Загрузите свою
                  фотографию
                </div>
                <div class="mt-[6px] text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Формат PNG,
                  JPG.
                  Вес до 2 Mb.
                </div>
              </div>
            </template>

          </div>


<!--          <UIFileUploader v-model="authStore.me.avatar">-->
<!--            <template #default="data">-->
<!--              <sheet class="!w-[231px] !h-[308px] relative cursor-pointer overflow-hidden empty-photo-bg"-->
<!--                @click="trigger(data)">-->
<!--                <template v-if="data.data.imageUrl">-->
<!--                  <img :src="data.data.imageUrl" class="w-full h-full object-cover absolute left-0 top-0">-->
<!--                  <div-->
<!--                    class="absolute bottom-0 left-0 w-full h-[46px] text-center bg-black bg-opacity-20 backdrop-blur-[5px] flex gap-[8px] items-center justify-center">-->
<!--                    <div class="text-white text-base leading-5 font-normal">Редактировать</div>-->
<!--                    <div>-->
<!--                      <UIIcon size="24" name="camera" color="white"></UIIcon>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <template v-else>-->
<!--                  <img src="/images/camera.svg" class="absolute top-[86px] left-[66px]">-->
<!--                  <div class="absolute bottom-0 mb-[34px] text-center">-->
<!--                    <div class="text-[#0A5CD5] font-light text-[0.75rem] leading-[0.9375rem]">Загрузите свою-->
<!--                      фотографию-->
<!--                    </div>-->
<!--                    <div class="mt-[6px] text-[#7B7A7A] text-[0.75rem] leading-[0.9375rem] font-light">Формат PNG, JPG.-->
<!--                      Вес до 2 Mb.-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->

<!--              </sheet>-->
<!--            </template>-->
<!--          </UIFileUploader>-->

          <div class="flex flex-col gap-y-[32px] w-[342px] ml-[18px]">
            <div class="flex flex-col gap-y-[12px]">
              <UIInput v-model="authStore.me.first_name"
                inputClass="text-[#0A5CD5] font-medium text-[20px] !h-[48px] !px-[8px] !py-[12px]"
                outlineColor="#0A5CD5" placeholder="Имя">
                
              </UIInput>
              <UIInput v-model="authStore.me.last_name"
                inputClass="text-[#0A5CD5] font-medium text-[20px] !h-[48px] !px-[8px] !py-[12px]"
                outlineColor="#0A5CD5" placeholder="Фамилия">
              </UIInput>
            </div>
            <!--          <Select :options="cities" v-model="authStore.me.city_of_residence" :errorText="registrationFormErrors.city"-->
            <!--            class="mb-[12px]" placeholder="Город"></Select>-->
            <div class="flex flex-col gap-y-[8px]">
              <UserProfileField ref="cityField" @edit="cityFieldSelect.toggleDropdown()" @save="save">
                <template #icon>
                  <UIIcon size="16" name="geoAlt" color="#0A5CD5"></UIIcon>
                </template>
                <template #input>

                  <UISelect :options="cities" v-model="authStore.me.city_of_residence"
                    :errorText="registrationFormErrors.city" placeholder="Город"
                    trigger-class="!border-0 !outline-none !p-0 ml-[-2px] text-[#1F1E1E] !pl-0 w-[160px]" ref="cityFieldSelect">

                    <template #trigger="data">
                      {{ data.data }}
                    </template>
                    <template #append-inner>
                      <span></span>
                    </template>

                  </UISelect>

                  <!--                <UIInput v-model="form.city" inputClass="!border-0 !outline-none !p-0 ml-[-2px] text-[#1F1E1E]">-->
                  <!--                </UIInput>-->

                </template>
                <template #button>
                  <div class="user-profile-field-button">Изменить город</div>
                </template>

              </UserProfileField>
              <UserProfileField ref="dateField" @edit="dateFieldSelect.open()" @save="save">
                <template #icon>
                  <UIIcon size="16" name="crossedEye" color="#0A5CD5"></UIIcon>
                </template>
                <template #input>
                  <UIDatePicker v-model="authStore.me.born_date" trigger-class="border-0 px-0 w-[80px] focus:outline-0" ref="dateFieldSelect">
                  </UIDatePicker>

                  <!--                <UIInput v-model="form.date" inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] max-w-[80px]">-->
                  <!--                </UIInput>-->
                </template>
                <template #dataIcon>
                  <UIIcon size="16" name="calendarFrame" color="#7B7A7A"></UIIcon>
                </template>
                <template #button>
                  <div class="user-profile-field-button ">Изменить дату</div>
                </template>
              </UserProfileField>
              <UserProfileField ref="citizenshipField">
                <template #icon>
                  <UIIcon size="20" name="person" color="#0A5CD5"></UIIcon>
                </template>
                <template #input>
                  <UIInput v-model="authStore.me.citizenship" inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E]">
                  </UIInput>
                </template>
                <template #button>
                  <div class="user-profile-field-button">Изменить гражданство</div>
                </template>
              </UserProfileField>
              <UserProfileField ref="sexField">
                <template #icon>
                  <UIIcon size="20" name="person" color="#7B7A7A"></UIIcon>
                </template>
                <template #input>

                  <UISelect v-model="authStore.me.sex"
                    trigger-class="!border-0 !outline-none !p-0 ml-[-2px] text-[#1F1E1E] !pl-0 w-[160px]"
                    class="!w-[160px]" :options="[
                      { title: 'Мужской', value: 'male' },
                      { title: 'Женский', value: 'female' },
                      { title: 'Не указывать', value: 'no_select' },
                    ]">
                    <template #trigger="data">
                      {{ data.data }}
                    </template>
                    <template #append-inner>
                      <span></span>
                    </template>
                    <template #prepend-dropdown>
                      <span></span>
                    </template>
                  </UISelect>
                  <!-- <UIInput placeholder="Пол" v-model="form.sex" inputClass="!border-0 !outline-none !p-0 text-[#7B7A7A]"></UIInput> -->
                </template>
                <template #button>
                  <div class="user-profile-field-button">Указать пол</div>
                </template>
              </UserProfileField>
            </div>

          </div>
        </div>


        <div class="flex flex-col mt-[32px] gap-y-[8px]">
          <UserContactField :isVisible="authStore.me.is_email_visible" :isMain="authStore.me.is_email_main"
            @update:isVisible="authStore.me.is_email_visible = $event"
            @update:isMain="authStore.me.is_email_main = $event">
            <template #icon>
              <UIIcon size="16" name="envelope" color="#0A5CD5"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="authStore.me.email" inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] w-full">
              </UIInput>
            </template>
          </UserContactField>

          <UserContactField :isVisible=authStore.me.is_phone_number_visible :isMain="authStore.me.is_phone_number_main"
            @update:isVisible="authStore.me.is_phone_number_visible = $event"
            @update:isMain="authStore.me.is_phone_number_main = $event">
            <template #icon>
              <UIIcon size="16" name="telephone" color="#0A5CD5"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="authStore.me.phone_number" inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E]">
              </UIInput>
            </template>
          </UserContactField>

          <UserContactField :isVisible=authStore.me.is_telegram_contact_visible
            :isMain="authStore.me.is_telegram_contact_main"
            @update:isVisible="authStore.me.is_telegram_contact_visible = $event"
            @update:isMain="authStore.me.is_telegram_contact_main = $event">
            <template #icon>
              <UIIcon size="24" name="telegram"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="authStore.me.telegram_contact" inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E]">
              </UIInput>
            </template>
          </UserContactField>

          <UserContactField :isVisible=authStore.me.is_whatsapp_contact_visible
            :isMain="authStore.me.is_whatsapp_contact_main"
            @update:isVisible="authStore.me.is_whatsapp_contact_visible = $event"
            @update:isMain="authStore.me.is_whatsapp_contact_main = $event">
            >
            <template #icon>
              <UIIcon size="24" name="whatsApp"></UIIcon>
            </template>
            <template #input>
              <UIInput v-model="authStore.me.whatsapp_contact" inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E]">
              </UIInput>
            </template>
          </UserContactField>
        </div>
        <div class="flex flex-row justify-end">
          <div class="mt-[25px] w-full flex flex-row items-center">
            <div class="w-[50%] flex flex-row items-center gap-2">
              <UICheckbox v-model="authStore.me.is_student">
              </UICheckbox>
              <div v-if="authStore.me.is_student === true">Я студент</div>
              <div v-else>Я не студент</div>
              
            </div>
            <ButtonNew type="stroke" class="!w-[50%]" @click="save">Сохранить</ButtonNew>
          </div>
        </div>


      </Sheet>
      <Sheet v-else-if="miActive('/employee/account/settings?type=loginPasswords')"
        class="flex flex-col h-min !p-[32px] md:!rounded-[24px] gap-y-[20px]">
        <div class="flex flex-col h-[71px] justify-between">
          <div class="h-5">Адрес электронной почты</div>
          <UserProfileField>
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="envelope" color="#0A5CD5"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.email"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="flex flex-col h-[71px] justify-between">
          <div class="h-5">Номер телефона</div>
          <UserProfileField>
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="telephone" color="#0A5CD5"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.phone_number"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="flex flex-col h-[71px] justify-between">
          <div class="h-5">Пароль для входа в личный кабинет</div>
          <UserProfileField :borderColor="passwordsBorderColor">
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="eye" color="#1F1E1E"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.password"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>
          </UserProfileField>
        </div>
        <div class="flex flex-col gap-y-[8px] justify-between">
          <div class="h-5">Повторите пароль</div>
          <UserProfileField :borderColor="passwordsBorderColor">
            <template #icon>
              <div class="ml-1">
                <UIIcon size="16" name="eye" color="#1F1E1E"></UIIcon>
              </div>
            </template>
            <template #input>
              <UIInput v-model="loginPasswordForm.repeatPassword"
                inputClass="!border-0 !outline-none !p-0 text-[#1F1E1E] text-[16px]">
              </UIInput>
            </template>
            <template #button>
              <div class="inline-flex gap-[8px] justify-end cursor-pointer" @click="">
                <UIIcon size="12" name="pencil" color="#0A5CD5"></UIIcon>
                <div class="contact-action-item-text">Изменить</div>
              </div>
            </template>

          </UserProfileField>
          <div v-if="loginPasswordForm.password !== loginPasswordForm.repeatPassword"
            class="h-[15px] font-light text-[12px] text-[#FF3B30]">Пароли не совпадают
          </div>
        </div>
        <div class="inline-flex justify-end mt-[12px]">
          <ButtonNew v-if="!needUpdateLoginPassword" type="stroke-disabled" class="!w-[290px]">Сохранить
          </ButtonNew>
          <ButtonNew v-else type="stroke" @click="updateCredentials" class="!w-[290px]">Сохранить
          </ButtonNew>
        </div>
      </Sheet>
    </div>


  </LKLayout>
</template>

<script setup>

import LKLayout from "@/layouts/LKLayout";
import ButtonNew from "@/components/ui/ButtonNew";
import Sheet from "@/components/cards/Sheet";
import UIIcon from "@/components/ui/UIIcon";
import UIFileUploader from "@/components/ui/UIFileUploader";
import { ref, watch, reactive, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/authStore";
import UIInput from "@/components/ui/UIInput";
import UISelect from "@/components/ui/UISelect.vue";
import UserProfileField from "@/components/ui/UserProfileField.vue";
import UserContactField from "@/components/ui/UserContactField.vue";
import UIDatePicker from "@/components/ui/UIDatePicker";
import Select from "@/components/ui/Select.vue";
import { useFetch } from "@/composables/useFetch";
import { useToast } from "vue-toast-notification";
import { useRoute, useRouter } from "vue-router";
import {usePopupsStore} from "@/stores/popupsStore";
import {useMobile} from "@/composables/useMobile";
import Avatar from "@/components/ui/Avatar";
import UICheckbox from "@/components/ui/UICheckbox.vue";

const fetched = ref(false)

const route = useRoute()
const router = useRouter()
const popupsStore = usePopupsStore()
const mobile = useMobile()


const cityField = ref(null)
const dateField = ref(null)
const citizenshipField = ref(null)
const sexField = ref(null)
const cityFieldSelect = ref(null)
const dateFieldSelect = ref(null)



const miActive = (path) => {
  if (route.fullPath.includes(path)) {
    return true
  }
  return false
}

const changeAvatar = () => {

  popupsStore.openImageUploadCropperPopup((file) => {
    console.log('Data: ', file)
    authStore.me.avatar = file.id
  })
}

const loginPasswordForm = reactive({
  email: null,
  phone_number: null,
  password: '',
  repeatPassword: ''
})

const toast = useToast()

const fetch = useFetch()
const authStore = useAuthStore()

const trigger = (data) => {
  data.data.triggerFileInput()
  // data.triggerFileInput
}

const save = () => {

  authStore.updateMe({
    first_name: authStore.me.first_name,
    last_name: authStore.me.last_name,
    city_of_residence: authStore.me.city_of_residence,
    sex: authStore.me.sex,
    is_student: authStore.me.is_student,
    born_date: authStore.me.born_date,
    email: authStore.me.email,
    phone_number: authStore.me.phone_number,
    telegram_contact: authStore.me.telegram_contact,
    whatsapp_contact: authStore.me.whatsapp_contact,
    is_email_visible: authStore.me.is_email_visible,
    is_phone_number_visible: authStore.me.is_phone_number_visible,
    is_telegram_contact_visible: authStore.me.is_telegram_contact_visible,
    is_whatsapp_contact_visible: authStore.me.is_whatsapp_contact_visible,
    is_email_main: authStore.me.is_email_main,
    is_phone_number_main: authStore.me.is_phone_number_main,
    is_telegram_contact_main: authStore.me.is_telegram_contact_main,
    is_whatsapp_contact_main: authStore.me.is_whatsapp_contact_main,
    citizenship: authStore.me.citizenship
  })
  toast.success('Данные обновлены')
}

const form = reactive({
  firstName: "Сергей",
  lastName: "Смирнов",
  city: null,
  date: "1987-09-19", // Дату и время храним и передаем в формате ISO 8601
  country: "Россия",
  sex: null,
  email: 'example@mail.ru',
  phone: '+7 (495) 221-10-27',
  tg: 'https://t.me/SergeySm',
  whatsapp: 'https://wa.me/+79164384827'
})

const cities = ref([])

const registrationFormErrors = reactive({
  city: null
})

const isValidCity = (city) => {
  return cities.value.some(item => item.value === city);
}

watch(() => authStore.me.city_of_residence, (newValue) => {
  if (isValidCity(newValue)) {
    registrationFormErrors.city = null
  } else {
    registrationFormErrors.city = 'Укажите город правильно'
  }
});

const type = computed(() => {
  return route.query.type
})

watch(() => type.value, (v) => {

  if (!v) {
    router.push({
      path: route.path,
      query: {
        ...route.query,
        type: 'personalData'
      }
    })
    return
  }
})

const initialize = () => {
  if (!type.value) {
    router.push({
      path: route.path,
      query: {
        ...route.query,
        type: 'personalData'
      }
    })
  }
  const meRequest = authStore.getMe(true);
  const citiesRequest = fetch.anonRequest('/references/cities').then((data) => {
    cities.value = data.results.map(item => ({
      title: item,
      value: item,
    }))
  })
  Promise.all([meRequest, citiesRequest]).then(() => {
    fetched.value = true

    loginPasswordForm.email = authStore.me.email
    loginPasswordForm.phone_number = authStore.me.phone_number
  })
}

onMounted(() => {
  initialize();
});

const needUpdateLoginPassword = computed(() => {
  if (loginPasswordForm.password !== loginPasswordForm.repeatPassword)
    return false

  if (loginPasswordForm.email !== authStore.me.email || loginPasswordForm.phone_number !== authStore.me.phone_number || loginPasswordForm.password !== '')
    return true
  else
    return false
})

const updateCredentials = () => {
  let responseBody = {
    email: loginPasswordForm.email,
    phone_number: loginPasswordForm.phone_number
  }
  if (loginPasswordForm.password !== '')
    responseBody.password = loginPasswordForm.password
  fetch.authRequest(`/set_credentials/`, {
    method: 'PUT',
    body: {
      ...responseBody
    }
  }).then(response => {
    toast.success('Ожидает подтверждения. Подтвердите изменения по ссылке в письме')
    loginPasswordForm.password = ''
    loginPasswordForm.repeatPassword = ''
    // initialize()
  })
}

const passwordsBorderColor = computed(() => {
  if (loginPasswordForm.password === loginPasswordForm.repeatPassword)
    return '#7B7A7A'
  else
    return '#FF3B30'
});
</script>

<style scoped>
.empty-photo-bg {
  background-color: rgba(10, 92, 213, 0.05);
}

.name {
  color: #0A5CD5;
  /* Цвет текста */
  font-weight: 500;
  /* Средний вес шрифта (font-medium) */
  font-size: 20px;
  /* Размер шрифта */
  height: 48px;
  /* Высота */
  padding-left: 8px;
  /* Отступ слева */
  padding-right: 8px;
  /* Отступ справа */
  padding-top: 12px;
  /* Отступ сверху */
  padding-bottom: 12px;
  /* Отступ снизу */
}

.user-profile-field-button {

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  /* Blue */
  color: #0A5CD5;

  white-space: nowrap;
}

.contact-action-item-text {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: #0A5CD5;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>