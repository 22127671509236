<template>
    <div class="profile-field" :class="borderColorClass">
        <div class="flex flex-row gap-x-[16px] h-[20px] items-center justify-start w-full">
            <div>
                <slot name="icon"></slot>
            </div>
            <div ref="inputContainer" class="w-full">
                <slot name="input"></slot>
            </div>
            <div>
                <slot name="dataIcon"></slot>
            </div>
        </div>
        <div v-if="!change" class="flex flex-row h-[20px] items-center cursor-pointer" @click="clickEdit">
            <slot name="button">Изменить</slot>
        </div>
        <div v-else class="flex flex-row h-[20px] items-center cursor-pointer" @click="clickSave">
            <slot name="buttonSave">
                <div class="edit-button">Сохранить</div>
            </slot>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
    borderColor: {
        type: String,
        default: '#7B7A7A',
    },
});

const borderColorClass = computed(() => {
    return `border border-[${props.borderColor}]`
})

const inputContainer = ref(null);
const trigger = ref(null);

const change = ref(false)

const emit = defineEmits([
    'change',
    'save',
    'edit'
])

const focusInput = () => {
    const inputElement = inputContainer.value?.querySelector('input, textarea'); // Находим инпут или текстовое поле
    if (inputElement) {
        inputElement.focus(); // Устанавливаем фокус
    }

    emit('change')
    change.value = true
};

const clickSave = () => {
    emit('save')
    change.value = false
}

const clickEdit = () => {
    emit('edit')
    change.value = true
    focusInput; 
}
</script>

<style scoped>
.profile-field {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 12px 8px;

    background: transparent;
    border-radius: 15px;

    height: 44px;
}



.edit-button {

    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;

    /* Blue */
    color: #0A5CD5;

    white-space: nowrap;
}
</style>
