<template>
    <!-- {{ modelValue }} -->
    <UIDropdown ref="dropdown" :customLeftPosition="customLeftPosition">
        <template #trigger>
            <!-- <button class="px-4 py-2 bg-blue-500 text-white rounded w-[220px]">
                Меню
            </button> -->
            <div class="relative rounded-[0.94rem] md:h-[44px] h-[42px]" :class="dropdownClass">
                <div v-if="!dateFrom && !dateTo" class="absolute top-[13px] left-[16px] text-[#B7B7B7] text-[12px] font-fight cursor-pointer">Период</div>
                <div v-else class="md:text-[14px] text-[10px] absolute md:top-[12px] top-[14px] md:left-[16px] left-[8px]">{{ formatDateMinimal(dateFrom, true) }} - {{ formatDateMinimal(dateTo, true) }}</div>
                <div class="absolute z-0 right-[0.6rem] top-[13px]" >
                    <Icon v-if="!dropdownOpened" name="chevron-up-black" @click.stop="toggle"></Icon>
                    <Icon v-else name="chevron-down-black" @click.stop="toggle"></Icon>
                </div>
                <div v-if="errorText" class="error_message !z-0 md:mt-[60px] mt-[48px]">{{ errorText }}</div>
            </div>
            
            <!-- model value {{ modelValue }} . {{ dateFrom }} -->
        </template>
        
        <template #dropdown>
            <!-- 
            
            /* Сортировка */

position: absolute;
width: 200px;
height: 144px;
left: 599px;
top: 272px;

background: #FFFFFF;
/* Тень на белые карточки */
box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
border-radius: 0px 0px 12px 12px;

            -->

            <div class="calendar md:!w-[400px] !w-[250px] z-10 bg-white shadow-md rounded-b-lg " >
                <div class="flex flex-col">
                    <!-- <div>{{ dateFrom }}</div>
                    <div>---------------</div>
                    <div>{{ dateTo }}</div> -->
                </div>
                <div class="p-[16px_32px] flex justify-between w-full">

                    <button class="p-2" @click="prevMonth">
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.82539 1.175L5.65039 0L0.650391 5L5.65039 10L6.82539 8.825L3.00872 5L6.82539 1.175Z"
                                fill="black" />
                        </svg>
                    </button>
                    <div class="whitespace-nowrap text-gray-900 font-semibold text-5 leading-6">{{
                        monthNames[currentMonth] }}
                        {{ currentYear }}
                    </div>
                    <button class="p-2" @click="nextMonth">
                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.0002 0L0.825195 1.175L4.64186 5L0.825195 8.825L2.0002 10L7.0002 5L2.0002 0Z"
                                fill="black" />
                        </svg>
                    </button>
                </div>


                <div class="w-[calc(100%-26px)] h-[1px] bg-[#B7B7B7] mx-[13px] mb-[16px] "></div>


                <div class="w-full flex flex-row flex-wrap md:px-[32px] px-[16px]">
                    <div class="weekday md:w-[44px] w-[30px] text-[#1F1E1E] text-[0.75rem] leading-[0.75rem] font-medium tracking-[0.03em] uppercase"
                        v-for="(day, index) in weekDays" :key="index">{{ day }}</div>
                </div>
                <div class="w-full flex flex-row flex-wrap md:px-[32px] px-[16px]">
                    <div v-for="n in daysBefore" :key="`empty-${n}`" class="md:w-[44px] w-[30px] h-[42px] md:my-[4px] my-[2px]"></div>
                    <div v-for="day in daysInMonth" :class="dayInMonthClass(day)" :key="day" @click="selectDate(day)"
                        class="calendar-day md:w-[44px] w-[30px] h-[42px] md:my-[4px] my-[2px] flex items-center justify-center border-0 outline-0
                hover:bg-[#B7B7B7] hover:text-[white]
                text-[#1F1E1E] md:text-[1.125rem] md:leading-[1.375rem] text-[0.87rem] leading-[1rem] font-medium
                ">

                        {{ day }}
                    </div>
                </div>
                <div class="flex flex-row justify-between w-full gap-x-[10px] px-[32px] mb-[16px]">
                    <ButtonNew class="!h-[40px]" :type="clearButtonType" @click="clear">Сбросить</ButtonNew>
                    <ButtonNew class="!h-[40px]" :type="applyButtonType" @click.stop="apply">Применить</ButtonNew>
                </div>
            </div>


        </template>

    </UIDropdown>

</template>

<script setup>
import { ref, computed, onMounted, nextTick, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { useToast } from "vue-toast-notification";
import UIDropdown from './UIDropdown.vue';
import ButtonNew from './ButtonNew.vue';
import Icon from "@/components/ui/Icon";
import {
    formatDateTime, formatexperienceForVacancy, formatexperienceForVacancyNew,
    sendMetrick, formatPrice, formatDate, formatDateMinimal
} from '@/composables/utils';

const props = defineProps({
    modelValue: {
        type: Object,
        default() {
            return {
                from: null,
                to: null
            };
        }
    },
        customLeftPosition: {
        default: null
    },
    // modelValue: {
    //     type: String,
    //     default: ''
    // },
    disabled: {
        default: false,
    },
    triggerClass: {
        default: null,
    },
    errorText: {
        default: null
    }
});
const emit = defineEmits(['update:modelValue']);
const dropdown = ref(null)
const dropdownOpened = ref(false)

watch(
  () => dropdown.value?.isOpen,
  (newValue) => {
    dropdownOpened.value = !dropdownOpened.value
  }
);



// function parseDateString(dateString) {
//   // Разделяем строку по точкам
//   const parts = dateString.split(".");

//   // Получаем день, месяц и год
//   const day = parseInt(parts[0], 10);
//   const month = parseInt(parts[1], 10) - 1; // Месяцы в JavaScript начинаются с 0
//   const year = parseInt(parts[2], 10);

//   // Создаем объект Date
//   return new Date(year, month, day);
// }

const formattedDate = (date) => {
  if (!date) {
    return ''; // Возвращаем пустую строку, если date равно null
  }

  return date.toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
};

const toggle = () => {

    dropdown.value.toggle() 
}

const apply = () => {
    console.log('zzz', dateFrom.value, dateTo.value)
    let obj = {
        from: null,
        to: null
    }
    if (dateFrom.value) {
        const nextDay = new Date(dateFrom.value);
        nextDay.setDate(nextDay.getDate() + 1); // Прибавляем 1 день
        obj.from = nextDay.toISOString().split('T')[0]
    }
    if (dateTo.value) {
        const nextDay = new Date(dateTo.value);
        nextDay.setDate(nextDay.getDate() + 1); // Прибавляем 1 день
        obj.to = nextDay.toISOString().split('T')[0]
    }

    emit('update:modelValue', obj);
    dropdown.value.hide();
}

const clear = () => {
    dateFrom.value = null
    dateTo.value = null
}

const clearButtonType = computed(() => {
    if (dateFrom.value) {
        return 'stroke'
    } else return 'stroke-disabled'
});

const applyButtonType = computed(() => {
    if ((dateFrom.value && dateTo.value) || (!dateFrom.value && !dateTo.value)) {
        return 'blue'
    } else return 'stroke-disabled'
});

const dropdownClass = computed(() => {
    if (props.errorText) {
        return 'border-[1px] border-[#FF3B30]'
    } else return 'border-[1px] border-[#0A5CD5]'
});



// const inputRef = ref(null)

// const isCalendarVisible = ref(false);
const currentDate = ref(new Date());
const currentMonth = ref(currentDate.value.getMonth());
const currentYear = ref(currentDate.value.getFullYear());

const dateFrom = ref(props.modelValue?.from || null)
const dateTo = ref(props.modelValue?.to || null)

const toast = useToast()

const monthNames = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
    'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

const weekDays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

const daysInMonth = computed(() => new Date(currentYear.value, currentMonth.value + 1, 0).getDate());

const dayInMonthClass = (day) => {
    const dayDate = new Date(currentYear.value, currentMonth.value, day)
    if (dateFrom.value && dateTo.value) {
        console.log('dateFrom.value', dateFrom.value, 'dateTo.value', dateTo.value, 'dayDate', dayDate)
        console.log(dateFrom.value.getTime() <= dayDate.getTime(), dateFrom.value.getTime(), dayDate.getTime(), dateTo.value.getTime())
        if (dateFrom.value.getTime() <= dayDate.getTime() && dayDate.getTime() <= dateTo.value.getTime())
            return 'bg-[#0A5CD5] text-white'
    }
    else {
        // console.log("dayDate", dayDate, 'dateFrom.value', dateFrom.value)
        // console.log("BBBBB!!!!", dateFrom.value === dayDate)
        if (dayDate.getTime() === dateFrom.value?.getTime()) {
            return 'bg-[#0A5CD5] text-white'
        }
        // if (dayDate == dateTo.value) {
        //     return 'bg-[#01B00A]'
        // }
    }


}

// watch(
//   () => props.modelValue?.from,
//   (newValue) => {
//     if (newValue !== null) {
//         props.modelValue.from = dateFrom.value
//         console.log('props.modelValue.from изменилось на:', newValue);
//     }
        
//   }
// );

const firstDayOfMonth = computed(() => new Date(currentYear.value, currentMonth.value, 1).getDay() || 7);

const daysBefore = computed(() => {
    return (firstDayOfMonth.value + 6) % 7;
});


// let inputRect = ref(null)

// const toggleCalendar = () => {
//     if (!props.disabled) {
//         isCalendarVisible.value = !isCalendarVisible.value;
//         if (isCalendarVisible.value) {
//             // document.getElementById('1').getBoundingClientRect()
//             inputRect.value = inputRef.value.getBoundingClientRect()
//         }
//     }
// };

const prevMonth = () => {
    currentMonth.value = (currentMonth.value - 1 + 12) % 12;
    currentYear.value -= currentMonth.value === 11 ? 1 : 0;
};

const nextMonth = () => {
    currentMonth.value = (currentMonth.value + 1) % 12;
    currentYear.value += currentMonth.value === 0 ? 1 : 0;
};

const selectDate = (day) => {
    

    const selectedDate = new Date(currentYear.value, currentMonth.value, day);
    // Если 'Дата от' не выбрана, то выбираем ее без ограничений
    if (dateFrom.value === null) {
        dateFrom.value = selectedDate

        console.log('selectDate!1', dateFrom.value)
        console.log('selectDate!2', dateTo.value)
        return
        // Если 'Дата от' выбрана, а 'Дата до' нет, то выбираем дату до не раньше чем дата от
    } else if (dateTo.value === null) {
        if (selectedDate > dateFrom.value)
            dateTo.value = selectedDate
        console.log('selectDate!1', dateFrom.value)
        console.log('selectDate!2', dateTo.value)
        return
    }

    // emit('update:modelValue', selectedDate.toISOString().split('T')[0]);
    // isCalendarVisible.value = false;
};

// const formattedDate = computed(() => {
//     return props.modelValue ? new Date(props.modelValue).toLocaleDateString('ru-RU', {
//         year: 'numeric',
//         month: 'numeric',
//         day: 'numeric'
//     }) : '';
// });

// function parseDateString(dateString) {
//     // Разделяем строку по точкам
//     const parts = dateString.split(".");

//     // Получаем день, месяц и год
//     const day = parseInt(parts[0], 10);
//     const month = parseInt(parts[1], 10) - 1; // Месяцы в JavaScript начинаются с 0
//     const year = parseInt(parts[2], 10);

//     // Создаем объект Date
//     return new Date(year, month, day);
// }

// function isValidDate(dateString) {
//     // Регулярное выражение для проверки формата "ДД.ММ.ГГГГ"
//     const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;

//     // Проверка соответствия формату
//     if (!regex.test(dateString)) {
//         return false;
//     }

//     // Разделяем дату на компоненты
//     const [day, month, year] = dateString.split('.').map(Number);

//     // Создаем объект даты
//     const date = new Date(year, month - 1, day);

//     // Проверяем, совпадают ли данные с переданными
//     return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
// }


onMounted(() => {
    const dt = new Date()
    currentDate.value = dt;
    currentMonth.value = dt.getMonth()
    currentYear.value = dt.getFullYear()

    updateFromModelValue()

    // dateFrom.value =  props.modelValue?.from
    // dateTo.value =  props.modelValue?.to
})


const updateFromModelValue = () => {
    if (props.modelValue?.from) {
        dateFrom.value = new Date(props.modelValue?.from)
    }
    if (props.modelValue?.to) {
        dateTo.value = new Date(props.modelValue?.to)
    }
}

watch(
    () => props.modelValue,
    (newValue) => {
        updateFromModelValue()
    }
)

// const updateDate = (event) => {
//     if (!isValidDate(event.target.value)) {
//         toast.warning('Некорректная дата')
//         selectDate(currentDate.value.getDay())
//         nextTick(() => {
//             emit('update:modelValue', currentDate.value.toISOString().split('T')[0]);

//         })
//         return
//     }
//     let selectedDate = parseDateString(event.target.value)

//     selectedDate.setDate(selectedDate.getDate() + 1); // Добавляем 1 к месяцу
//     currentDate.value = selectedDate;
//     currentMonth.value = selectedDate.getMonth()
//     currentYear.value = selectedDate.getFullYear()
//     emit('update:modelValue', selectedDate.toISOString().split('T')[0]);
//     isCalendarVisible.value = false;


// };
</script>

<style>
.calendar {
    position: absolute;
    /*border: 1px solid #ccc;*/
    background: white;
    width: 200px;

    /* Desktop Date Birth */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    width: 256px;

    /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));*/
    border-radius: 4px;

}

.calendar-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;


    /* Subtitle 2 */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.1px;

    /* 00. On Surface / Medium Emphasis */
    color: rgba(0, 0, 0, 0.6);


}

.weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    font-weight: bold;
}

.weekday {
    text-align: center;
    padding: 5px 0;


    /* Caption */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    /* 00. On Surface / Medium Emphasis */
    color: rgba(0, 0, 0, 0.6);


}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.empty-day {
    padding: 10px;
}

.calendar-day {
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    border: 2px solid transparent;
}

/*.calendar-day:hover {*/


/*  border: 2px solid #2894FF;*/
/*  border-radius: 5px;*/


/*}*/

.calendar-input {
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 26px;
    gap: 10px;

    background: #FFFFFF;
    border: 1px solid #ECECEC;
    border-radius: 15px;

    height: 42px;
}

.calendar-input[disabled] {
    opacity: 0.5;
}

.error-message {

    z-index: 0;
    font-style: normal;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 0px;
    line-height: 22px;
    letter-spacing: 0.04em;

    color: #DB4545;
}
</style>