<!-- <template>
    <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>
    <div id="page" class="flex flex-col h-full overflow-auto"  style="background-color: white">
      <div class="flex-grow md:mt-[64px] mt-[56px]">
        <slot name="default">
        </slot>
      </div>
  
      <FooterNew class="box-border"></FooterNew>
    </div>
  
  </template> -->
<template>
    <!-- <div class="z-[1999] absolute top-0 left-0  bg-red-500">Is auth: {{ authStore.isAuthenticated }}</div> -->
  
  <template v-if="authStore.isAuthenticated !== null">
    
    <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>

    <!-- Основной контейнер -->
    <div id="page" class="flex flex-col min-h-screen bg-white">

      <!-- Основное содержимое страницы -->
      <div class="flex-grow md:mt-[64px] mt-[56px]">
        <slot name="default">
          <!-- Содержимое странички (дефаулт) -->
        </slot>
      </div>

      <!-- Футер -->
      <FooterNew class="box-border"></FooterNew>
    </div>
  </template>
</template>

<script setup>
import { onMounted, ref, onBeforeMount } from "vue";
import Header from "@/components/ui/Header.vue";
import Footer from "@/components/ui/Footer.vue";
import FooterNew from "@/components/ui/FooterNew";
import HeaderNew from "@/components/ui/HeaderNew";
import { useAuthStore } from "@/stores/authStore";
import { useRouter } from "vue-router";
const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

const authStore = useAuthStore()
const router = useRouter()

onMounted(() => {
  authStore.getMe()

})

</script>

<style></style>