<template>
  <popup @close="emit('update:modelValue', false)" class="md:p-[49px_84px] md:w-[460px] pt-[3.5rem] px-[1.5rem] pb-[2.6rem]">
    <template #close>
      <div></div>
    </template>
    <template #default>
      <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem] mb-[1.1rem] md:mb-[25px] text-center">
        Чтобы оставить свой отклик на вакансию, вам необходимо
      </div>
      <div @click="send('/signup')"
           class="md:text-[16px] md:leading-[19.6px] text-[1rem] mb-[8px] font-bold text-[#0A5CD5] cursor-pointer text-center">
        зарегистрироваться
      </div>
      <div class="md:text-[16px] md:leading-[19.6px] text-[0.87rem] mb-[12px] text-center">
        или
      </div>
      <div @click="send('/login')"
           class="md:text-[16px] md:leading-[19.6px] text-[1rem] font-bold text-[#0A5CD5] cursor-pointer text-center">
        войти в личный кабинет
      </div>
    </template>

  </popup>
</template>

<script setup>

import Popup from "@/components/ui/Popup";

import {defineProps, defineEmits} from "vue";
import UITextArea from "@/components/ui/UITextArea";
import ButtonNew from "@/components/ui/ButtonNew";
import {usePopupsStore} from "@/stores/popupsStore";
import {useAuthStore} from "@/stores/authStore";
import {useRouter} from "vue-router";

const router = useRouter();

const authStore = useAuthStore()

const popupsStore = usePopupsStore()

const props = defineProps({
  modelValue: {
    default: false,
  }
})

const emit = defineEmits([
  'update:modelValue'
])

const send = (to) => {
  popupsStore.authorize.visible = false
  router.push(to)
}
</script>

<style scoped>

</style>