<!-- <template>
    <div class="relative !h-[500px]">
        <input type="text" v-model="searchTerm" @focus="showDropdown = true" @blur="hideDropdown"
            class="border p-2 w-full rounded" placeholder="Введите название города..." />
        <div v-if="showDropdown" class="absolute left-0 right-0 bg-white border rounded mt-1 max-h-60 overflow-y-auto">
            <ul>
                <li v-for="option in filteredOptions" :key="option" @mousedown.prevent="selectOption(option)"
                    class="p-2 hover:bg-gray-200 cursor-pointer">
                    {{ option }}
                </li>
            </ul>
        </div>
    </div>
</template> -->
<template>
  <div :class="computedClasses" class="select-none">
    <div class="relative w-full h-[48px] flex items-center " :class="wrapperClass">
      <slot name="selected" :selected="searchTerm" :selectedOption="selectedOption" :onFocus="onFocus" :onBlur="onBlur" :isFocused="isFocused">
          <input ref="inputRef" :class="computedInputClass" class="select-auto " type="text" :disabled="!filterable" :style="{ outline: outlineColor }" :placeholder="placeholder"
                 v-model="searchTerm" @focus="onFocus" @blur="onBlur">
      </slot>
      <slot name="chevron">
        <div class="z-20 absolute  right-[0.6rem]">
          <!--        <Icon @click="onBlur" v-if="isFocused" name="chevron-up-black"></Icon>-->
          <!--        <Icon @click="onFocus" v-else name="chevron-down-black"></Icon>-->
          <Icon @click="inputRef.blur()" v-if="isFocused" name="chevron-up-black"></Icon>
          <Icon @click="inputRef.focus()" v-else name="chevron-down-black"></Icon>
        </div>
      </slot>


      <div ref="dropdownElement" v-if="isFocused"
           class="z-[-1] bg-white border-[1px] border-t-0 border-[#1F1E1E] rounded-b-[12px]  max-h-[500px] overflow-y-auto w-full overflow-hidden-scroll">
        <div class="h-[25px]"></div>
        <div>
          <div class="text-[0.75rem] text-center" v-if="filteredOptions.length === 0">Пусто</div>
          <template v-for="option in filteredOptions" :key="option">
            
            <div @mousedown.prevent="selectOption(option)">
              <slot name="option" :option="option">
                <div
                  class="p-2 hover:bg-gray-200 cursor-pointer md:text-[16px] text-[0.75rem]">
                  {{ option.title }}

                </div>
              </slot>
            </div>


          </template>

        </div>
        <div class="h-[25px]"></div>
      </div>
    </div>
    <slot name="error">
      <div v-if="errorText" class="w-full text-left font-light text-[12px] mt-[12px] text-[#FF3B30]" :class="errorTextClass">{{ errorText }}</div>
    </slot>

  </div>
</template>

<script setup>
import {defineProps, onMounted, ref, watch, defineEmits, computed, toRaw, nextTick} from 'vue';
import Icon from "@/components/ui/Icon";

const inputRef = ref(null)

const props = defineProps({
  type: {
    type: String,
    default: 'default'
  },
  filterable: {
    default: true,
  },
  placeholder: {
    type: String,
    default: 'Введите что нибудь'
  },
  class: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  },
  wrapperClass: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  hiddenDefault: {
    default: true,
  },
  outlineColor: {
    default: '#1F1E1E'
  },
  outlineNoFocusColor: {
    default: '#B7B7B7'
  },
  errorText: {
    default: null
  },
  options: {
    default: []
  },
  noMountedChoice: {
    default: false
  },
  errorTextClass: {
    default: ''
  }
});



const dropdownElement = ref(null)
const isFocused = ref(false)
const onFocus = () => {
  placeholder.value = null
  isFocused.value = true; // Установить фокус
}
const onBlur = () => {
  isFocused.value = false; // Снять фокус

  if (searchTerm.value == '') {
    placeholder.value = props.placeholder
  }
}

watch(() => isFocused.value, (v) => {
  if (v) {
    const r = inputRef.value.getBoundingClientRect()

    nextTick(() => {
      const r1 = dropdownElement.value.getBoundingClientRect()
      dropdownElement.value.style.position = 'fixed'
      if (r1.bottom > window.innerHeight) {
        dropdownElement.value.style.top = `${r.top - r1.height}px`
      }
      else {
        dropdownElement.value.style.top = `${r.top + r.height}px`
      }
      dropdownElement.value.style.zIndex = `999`
      dropdownElement.value.style.width = `${r.width}px`

      // dropdownElement.value.style.left = r.left + 'px'
      // dropdownElement.value.style.top = r.top + r.height + 50 + 'px'
    })
  }
})

const outlineColor = computed(() => {
    // Условие для изменения цвета outline в зависимости от состояния фокуса
    if (props.errorText) {
      return `2px solid #FF2D2D`;
    }
    if (isFocused.value === false) {
      return `1px solid ${props.outlineColor}`;
    } else {
      return `2px solid ${props.outlineColor}`;
    }
  }
)

onMounted(() => {
  searchTerm.value = props.modelValue
  selectedOption.value = props.modelValue
  // if (!props.noMountedChoice) {
  //   if (props.options.length > 0) {
  //   searchTerm.value = props.options[0].value
  //   selectedOption.value = props.options[0]
  //   }
  // }
})

const emit = defineEmits(['update:modelValue', 'search', 'selectOption']);
const searchTerm = ref(props.modelValue);
const selectedOption = ref(null)

const placeholder = ref(props.placeholder)

watch(searchTerm, (newValue) => {
  emit('update:modelValue', newValue);
});

watch(() => props.modelValue, () => {
  searchTerm.value = props.modelValue
})



const filteredOptions = computed(() => {
  if (props.filterable) {
    return props.options.filter(option => {
      return option.title.toLowerCase().includes(searchTerm.value.toLowerCase());
    });
  }
  return props.options

});

const selectOption = (option) => {
  searchTerm.value = option.value;
  isFocused.value = false;
  selectedOption.value = option

  emit('selectOption', option.value, option);
};

const standardClass = `z-20 absolute h-[48px] bg-[#FFFFFF] py-[1.06rem] px-[1rem] rounded-[0.94rem] md:px-[16px] md:py-0 md:rounded-[12px] text-left placeholder-placeholder-color font-normal md:text-[16px] text-[14px] w-full border !pr-[35px]`

// Генерируем классы на основе пропсов и стандартного набора классов
const computedClasses = computed(() => {
  return 'w-full ' + props.class
});

const computedInputClass = computed(() => {
  return standardClass + ' ' + props.inputClass
});

watch(() => isFocused.value, (isOpen) => {
  const element = document.getElementById('page')
  element.style.overflow = isOpen ? 'hidden' : 'auto'
})

</script>

<style scoped>
.custom-inner-border {
  position: relative;
}

.custom-inner-border::before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  border: 1px solid #888888;
  border-radius: calc(0.94rem - 1px);
  /* Adjusting border radius */
  pointer-events: none;
  /* Disable pointer events to make sure this pseudo-element doesn't interfere with button interactions */
}


@media (min-width: 769px) {
  .placeholder-placeholder-color::placeholder {
    color: #B7B7B7;
    /* Цвет плейсхолдера */
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .placeholder-placeholder-color::placeholder {
    color: #BDBDBD;
    /* Цвет плейсхолдера */
    font-size: 12px;
    font-weight: 300;
    text-align: left;
  }
}


/* Класс для ввода текста по левому краю */
input:not(:placeholder-shown) {
  text-align: left;
}


.overflow-hidden-scroll::-webkit-scrollbar {
  display: none; /* Скрыть полосу прокрутки */
}

.overflow-hidden-scroll {
  scrollbar-width: none; /* Firefox 64 и выше */
  -ms-overflow-style: none; /* Internet Explorer и Edge */
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>