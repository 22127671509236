<template>
  <template v-if="!expand">
    <Sheet class="h-min flex justify-between items-center cursor-pointer rounded-[24px] !w-[17.5rem] md:!w-full py-[8px] px-[12px]" @click="toggle">
      <slot name="header">
        <div class="flex flex-col gap-[8px]">
          <div class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
            {{ title }}
          </div>
          <div
            class="text-secondary-text md:text-base md:leading-5 text-[10px] leading-[12px] md:font-normal font-light">
            {{ subtitle }}
          </div>
        </div>

      </slot>

      <div>
        <UIIcon v-if="!mobile.isMobile.value" size="32" name="chevronDown" color="#0A5CD5"></UIIcon>
        <UIIcon v-else size="16" name="chevronDown" color="#0A5CD5"></UIIcon>
      </div>
    </Sheet>
  </template>
  <template v-else>
    <Sheet class="h-min !p-0 !rounded-[24px] !w-[17.5rem] md:!w-full">
      <div
        class="md:p-[8px_32px] py-[8px] px-[12px] pr-h-[68px] bg-[#0A5CD5] bg-opacity-10 w-full left-0 flex justify-between items-center cursor-pointer rounded-t-[24px]"
        @click="toggle">
        <slot name="header-expand">
          <div class="flex flex-col gap-[8px]">
            <div class="md:font-medium md:text-[1.5rem] md:leading-[1.8125rem] text-[18px] leading-[22px] font-normal text-black">
              {{ title }}
            </div>
          </div>
        </slot>

        <div>
          <UIIcon v-if="!mobile.isMobile.value" size="32" name="chevronUp" color="#0A5CD5"></UIIcon>
          <UIIcon v-else size="16" name="chevronUp" color="#0A5CD5"></UIIcon>
        </div>
      </div>

      <div class="md:p-[24px_32px] py-[18px] px-[12px]">
        <slot></slot>
      </div>


    </Sheet>

  </template>
</template>

<script setup>

import { ref } from "vue";
import UIIcon from "@/components/ui/UIIcon";
import Sheet from "@/components/cards/Sheet";
import { useMobile } from "@/composables/useMobile";

const mobile = useMobile()

const expand = ref(false)

const props = defineProps({
  title: {
    default: 'Заголовок'
  },
  subtitle: {
    default: null
  },
})

const toggle = () => {
  expand.value = !expand.value
}

</script>

<style scoped></style>