<template>
  <!-- <div class="relative">
    <input ref="inputRef" class="calendar-input " type="text" @click="toggleCalendar" :value="formattedDate"
           @change="updateDate"

           :disabled="disabled" :class="triggerClass"/>
    <div v-if="isCalendarVisible && inputRect" class="calendar w-[381px]  z-10 " :style="{
      position: 'absolute',
      top: inputRect.top + inputRect.height,
      left: inputRect.left
      }">
      <div class="p-[16px_32px] flex justify-between w-full">

        <button class="p-2" @click="prevMonth">
          <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.82539 1.175L5.65039 0L0.650391 5L5.65039 10L6.82539 8.825L3.00872 5L6.82539 1.175Z"
                  fill="black"/>
          </svg>
        </button>
        <div class="whitespace-nowrap text-gray-900 font-semibold text-5 leading-6">{{ monthNames[currentMonth] }}
          {{ currentYear }}
        </div>
        <button class="p-2" @click="nextMonth">
          <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.0002 0L0.825195 1.175L4.64186 5L0.825195 8.825L2.0002 10L7.0002 5L2.0002 0Z" fill="black"
            />
          </svg>
        </button>
      </div>


      <div class="w-[calc(100%-26px)] h-[1px] bg-[#B7B7B7] mx-[13px] mb-[16px] "></div>


      <div class="w-full flex flex-row flex-wrap px-[32px]">
        <div class="weekday flex-grow text-[#1F1E1E] text-[0.75rem] leading-[0.75rem] font-medium tracking-[0.03em] uppercase" v-for="(day, index) in weekDays" :key="index">{{ day }}</div>
      </div>
      <div class="w-full flex flex-row flex-wrap px-[32px]">
        <div v-for="n in daysBefore" :key="`empty-${n}`" class="w-[35.57px] h-[42px] my-[4px]"></div>
        <div v-for="day in daysInMonth" :key="day" @click="selectDate(day)"
             class="calendar-day w-[35.57px] h-[42px] my-[4px] flex items-center justify-center border-0 outline-0
              hover:bg-[#B7B7B7] hover:text-[white] rounded-[8px]
              text-[#1F1E1E] text-[1.125rem] leading-[1.375rem] font-medium
              "

        >
          {{ day }}
        </div>
      </div>
    </div>
    <div v-if="errorText" class="error-message">{{errorText}}</div>
  </div> -->


  <UIDropdown ref="dropdown">
    <template #trigger>
      <input ref="inputRef" class="calendar-input w-full !outline-none !border-none !p-[2px_4px]" type="text" @click="toggleCalendar"
        :value="formattedDate" @change="updateDate" :disabled="disabled" :class="triggerClass" />
    </template>
    <template #dropdown>
      
      <div v-if="inputRect" class="calendar sheet !w-[330px]  z-10 " :style="{
        position: 'absolute',
        top: inputRect.top + inputRect.height,
        left: inputRect.left
      }">
        <div class="p-[16px_32px] flex justify-between w-full">

          <button class="p-2" @click="prevMonth">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.82539 1.175L5.65039 0L0.650391 5L5.65039 10L6.82539 8.825L3.00872 5L6.82539 1.175Z"
                fill="black" />
            </svg>
          </button>
          <div class="whitespace-nowrap text-gray-900 font-semibold text-5 leading-6">{{ monthNames[currentMonth] }}
            {{ currentYear }}
          </div>
          <button class="p-2" @click="nextMonth">
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.0002 0L0.825195 1.175L4.64186 5L0.825195 8.825L2.0002 10L7.0002 5L2.0002 0Z" fill="black" />
            </svg>
          </button>
        </div>


        <div class="w-[calc(100%-26px)] h-[1px] bg-[#B7B7B7] mx-[13px] mb-[16px] "></div>


        <div class="w-full flex flex-row flex-wrap px-[32px]">
          <div
            class="weekday  text-[#1F1E1E] text-[0.75rem] leading-[0.75rem] font-medium tracking-[0.03em] uppercase !w-[35.57px] !h-[42px] my-[4px]"
            v-for="(day, index) in weekDays" :key="index">{{ day }}</div>
        </div>
        <div class="w-full flex flex-row flex-wrap px-[32px]">
          <div v-for="n in daysBefore" :key="`empty-${n}`" class="w-[35.57px] h-[42px] my-[4px]"></div>
          <div v-for="day in daysInMonth" :key="day" @click="selectDate(day)" class="calendar-day w-[35.57px] h-[42px] my-[4px] flex items-center justify-center border-0 outline-0
              hover:bg-[#B7B7B7] hover:text-[white] rounded-[8px]
              text-[#1F1E1E] text-[1.125rem] leading-[1.375rem] font-medium
              ">
            {{ day }}
          </div>
        </div>
      </div>
    </template>
  </UIDropdown>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { useToast } from "vue-toast-notification";
import UIDropdown from './UIDropdown.vue';
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  disabled: {
    default: false,
  },
  triggerClass: {
    default: null,
  },
  errorText: {
    default: null
  }
});

const dropdown = ref(null)

const emit = defineEmits(['update:modelValue']);

const inputRef = ref(null)

const isCalendarVisible = ref(false);
const currentDate = ref(new Date());
const currentMonth = ref(currentDate.value.getMonth());
const currentYear = ref(currentDate.value.getFullYear());

const toast = useToast()

const monthNames = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

const weekDays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

const daysInMonth = computed(() => new Date(currentYear.value, currentMonth.value + 1, 0).getDate());

const firstDayOfMonth = computed(() => new Date(currentYear.value, currentMonth.value, 1).getDay() || 7);

const daysBefore = computed(() => {
  return (firstDayOfMonth.value + 6) % 7;
});


let inputRect = ref(null)

const toggleCalendar = () => {
  if (!props.disabled) {
    isCalendarVisible.value = !isCalendarVisible.value;
    if (isCalendarVisible.value) {
      // document.getElementById('1').getBoundingClientRect()
      inputRect.value = inputRef.value.getBoundingClientRect()
    }
  }
};

const prevMonth = () => {
  currentMonth.value = (currentMonth.value - 1 + 12) % 12;
  currentYear.value -= currentMonth.value === 11 ? 1 : 0;
};

const nextMonth = () => {
  currentMonth.value = (currentMonth.value + 1) % 12;
  currentYear.value += currentMonth.value === 0 ? 1 : 0;
};

const selectDate = (day) => {

  const selectedDate = new Date(currentYear.value, currentMonth.value, day + 1);
  emit('update:modelValue', selectedDate.toISOString().split('T')[0]);
  isCalendarVisible.value = false;
  dropdown.value.hide()
};

const formattedDate = computed(() => {
  return props.modelValue ? new Date(props.modelValue).toLocaleDateString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }) : '';
});

function parseDateString(dateString) {
  // Разделяем строку по точкам
  const parts = dateString.split(".");

  // Получаем день, месяц и год
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Месяцы в JavaScript начинаются с 0
  const year = parseInt(parts[2], 10);

  // Создаем объект Date
  return new Date(year, month, day);
}

function isValidDate(dateString) {
  // Регулярное выражение для проверки формата "ДД.ММ.ГГГГ"
  const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(\d{4})$/;

  // Проверка соответствия формату
  if (!regex.test(dateString)) {
    return false;
  }

  // Разделяем дату на компоненты
  const [day, month, year] = dateString.split('.').map(Number);

  // Создаем объект даты
  const date = new Date(year, month - 1, day);

  // Проверяем, совпадают ли данные с переданными
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}


onMounted(() => {
  const dt = props.modelValue ? new Date(props.modelValue) : new Date()
  currentDate.value = dt;
  currentMonth.value = dt.getMonth()
  currentYear.value = dt.getFullYear()
})

const updateDate = (event) => {
  if (!isValidDate(event.target.value)) {
    toast.warning('Некорректная дата')
    selectDate(currentDate.value.getDay())
    nextTick(() => {
      emit('update:modelValue', currentDate.value.toISOString().split('T')[0]);

    })
    return
  }
  let selectedDate = parseDateString(event.target.value)

  selectedDate.setDate(selectedDate.getDate() + 1); // Добавляем 1 к месяцу
  currentDate.value = selectedDate;
  currentMonth.value = selectedDate.getMonth()
  currentYear.value = selectedDate.getFullYear()
  emit('update:modelValue', selectedDate.toISOString().split('T')[0]);
  isCalendarVisible.value = false;


};


const open = () => {
  toggleCalendar()
  // isCalendarVisible.value = true;
  // if (dropdown.value) {
  //   dropdown.value.open()
  // }
}

const close = () => {
  isCalendarVisible.value = false;
  if (dropdown.value) {
    dropdown.value.hide()
  }
}


defineExpose({
  open,
  close
})
</script>

<style>

.calendar {
  position: absolute;
  /*border: 1px solid #ccc;*/
  background: white;
  width: 200px;

  /* Desktop Date Birth */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  width: 256px;

  /*filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));*/
  border-radius: 4px;

  box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
  border: 1px solid transparent; 

}

.calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 5px;


  /* Subtitle 2 */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.1px;

  /* 00. On Surface / Medium Emphasis */
  color: rgba(0, 0, 0, 0.6);


}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-weight: bold;
}

.weekday {
  text-align: center;
  padding: 5px 0;


  /* Caption */
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  /* 00. On Surface / Medium Emphasis */
  color: rgba(0, 0, 0, 0.6);


}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.empty-day {
  padding: 10px;
}

.calendar-day {
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  border: 2px solid transparent;
}

/*.calendar-day:hover {*/


/*  border: 2px solid #2894FF;*/
/*  border-radius: 5px;*/


/*}*/

.calendar-input {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 26px;
  gap: 10px;

  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 15px;

  height: 42px;
}

.calendar-input[disabled] {
  opacity: 0.5;
}

.error-message {


  font-style: normal;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 22px;
  letter-spacing: 0.04em;

  color: #DB4545;
}
</style>
