<template>
  <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M2.75895 11.7233C11.5797 7.88019 17.4616 5.34659 20.4046 4.12248C28.8075 0.627395 30.5535 0.0202617 31.6916 0.000213755C31.9419 -0.00419556 32.5016 0.0578375 32.8641 0.352003C33.1702 0.600391 33.2544 0.935928 33.2947 1.17143C33.335 1.40693 33.3852 1.9434 33.3453 2.36258C32.89 7.14708 30.9196 18.7578 29.9173 24.1165C29.4931 26.384 28.6579 27.1443 27.8494 27.2187C26.0923 27.3804 24.758 26.0574 23.0562 24.9418C20.3931 23.1962 18.8886 22.1095 16.3036 20.406C13.3162 18.4373 15.2528 17.3553 16.9553 15.587C17.4009 15.1242 25.1428 8.0823 25.2927 7.44348C25.3114 7.36359 25.3288 7.06578 25.1519 6.90853C24.975 6.75127 24.7138 6.80505 24.5254 6.84782C24.2583 6.90844 20.004 9.72039 11.7625 15.2837C10.5549 16.1129 9.46117 16.5169 8.48118 16.4957C7.40082 16.4724 5.32265 15.8849 3.77774 15.3827C1.88284 14.7667 0.37682 14.4411 0.507961 13.395C0.576267 12.8501 1.3266 12.2929 2.75895 11.7233Z"
      fill="#0A5CD5" />
  </svg>




</template>

<script setup>

</script>

<style scoped></style>