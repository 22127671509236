<template>
  <svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.9277 23.9756C7.79984 23.9756 0.312014 14.9758 0 0H6.57592C6.79191 10.9918 11.6397 15.6477 15.4796 16.6077V0H21.6718V9.47977C25.4637 9.0718 29.4471 4.75192 30.7912 0H36.9832C35.9512 5.85593 31.6313 10.1758 28.5594 11.9518C31.6313 13.3917 36.5515 17.1597 38.4234 23.9756H31.6073C30.1433 19.4157 26.4957 15.8877 21.6718 15.4077V23.9756H20.9277Z"
      fill="#0A5CD5" />
  </svg>



</template>

<script setup>

</script>

<style scoped></style>