import { onMounted, onUnmounted, ref } from "vue";
import { useReferencesStore } from "@/stores/referencesStore";
import { useFetch } from "./useFetch";
import { data } from "autoprefixer";
const { anonRequest, authRequest, baseURL } = useFetch();

export const useValidateFuncs = () => {

  const referencesStore = useReferencesStore()

  // В текущем варианте проверяет только на кол-во символов
  const isValidPassword = (password) => {
    return password.length > 5
  }

  const isValidCity = (city) => {
    // referencesStore.getCities().then(() => {
    //   const res = referencesStore.cities.some(item => item.value === city);
    //   console.log('res', res, referencesStore.cities)
    //   if (res == true) {
    //     return null
    //   } else {
    //     return 'Город не существует, выберите из списка'
    //   }
    // })
    const url = `${baseURL}/references/cities`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false); // Параметр `false` делает запрос синхронным
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send();

    if (xhr.status === 200) {
      const data = JSON.parse(xhr.responseText);
      console.log('response', data);
      if (data.results.some(item => item === city)) {
        return null;
      } else {
        return 'Город не совпадает, выберите из списка';
      }
    } else {
      return 'Ошибка при проверке email';
    }

  }

  const isNotEmpty = (string) => {
    let s = string
    if (s === null) {
      return 'Поле не должно быть пустым'
    } else {
      s = String(s)
    }
    if (s.length > 0) {

      return null
    } else {
      return 'Поле не должно быть пустым'
    }
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailPattern.test(email)) {
      return null
    } else {
      return 'Невалидный email'
    }
  }

  const isValidPhoneNumber = (phoneNumber) => {
    const regex = /^(7|\+7|8)\d{10}$/;
    if (regex.test(phoneNumber)) {
      return null
    } else {
      return 'Невалидный номер телефона'
    }
  }

  const isValidNumber = (input) => {
    // Проверяем, что ввод состоит только из цифр и является целым числом

    if (input === null || input === undefined || input === '') {
      return null; // Возвращаем null для невалидных значений
    }
    
    const isInteger = /^-?\d+$/.test(input); // Поддержка отрицательных чисел

    if (isInteger) {
      return null; // Целое число валидно
    } else {
      return 'Невалидное целое число'; // Не валидное значение
    }
  };

  // const isEmailAvailable = (email) => {
  //   const url = `/auth/email-check/`;
  //   const responseBody = {
  //     "email": email,
  //   }
  //   let result;
  //   const req = anonRequest(url, {
  //     method: 'POST',
  //     body: responseBody
  //   }).then(data => {
  //     result = data
  //   })

  //   if (result === true)
  //     return 'afafafa'
  //   else {
  //     console.log('result', result)
  //     return 'lol'
  //   }


  // };
  function isEmailAvailable(email) {
    const url = `${baseURL}/auth/email-check/`;
    const responseBody = {
      "email": email,
    };

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, false); // Параметр `false` делает запрос синхронным
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(responseBody));

    if (xhr.status === 200) {
      const data = JSON.parse(xhr.responseText);
      console.log('response', data);
      if (data === true) {
        console.log('data true');
        return 'email уже зарегистрирован';
      } else {
        return null;
      }
    } else {
      return 'Ошибка при проверке email';
    }
  }

  const isEqual = (fieldVal, comparisoVal) => {
    if (fieldVal !== comparisoVal)
      return 'Значения не совпадают'
    else
      return null
  }

  const isMinimalLen = (fieldVal, minimalLength) => {
    if (fieldVal.length < minimalLength)
      return `Значение не должно быть короче ${minimalLength} символов`
    else
      return null
  }

  const isValidExperiencies = (experiencies) => {
    // Строим структуру для  вот такого: {{formValidator.errors?.educations[0].name }}
    const result = experiencies.map(experience => ({
      name: null,
    }))

    // Поиск невалидных значений
    for (const i = 0; i < experiencies.length; i++) {
      const experience = experiencies[i]
      // Вот так для каждого поля
      const nameValidationError = isNotEmpty(experience.name) || isMinimalLen(experience.name, 5)
      if (nameValidationError) {
        result[i].name = nameValidationError
      }
    }

    return result
  }
  
  const isValidDate = (date) => {
    // Проверяем, что дата введена в формате YYYY-MM-DD, 2025-03-15
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (datePattern.test(date)) {
      return null
    } else {
      return 'Некорректная дата'
    }
  }

  const isValidPeriod = (period) => {
    // period - это объект с полями from и to
    // from и to - это даты в формате YYYY-MM-DD
    if (period.from > period.to) {
      return 'Дата окончания не может быть меньше даты начала'
    } else {
      return null
    }
  }
  return {
    isValidPassword,
    isValidCity,
    isNotEmpty,
    isValidEmail,
    isValidPhoneNumber,
    isValidNumber,
    isEmailAvailable,
    isEqual,
    isMinimalLen,
    isValidExperiencies,
    isValidDate,
    isValidPeriod
  };
};