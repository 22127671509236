<template>
  <DefaultLayout>

    <!--  <HeaderNew @toggleScroll="handleToggleScroll"></HeaderNew>-->
    <authTemplateCard>
<!--      <div class="flex flex-row w-full">-->
<!--        <div class="flex flex-row items-center relative w-full md:mb-[32px] mb-[5.14rem]">-->
<!--          <div class="absolute left-0">-->
<!--            <Icon @click="router.back()" v-if="isMobile" name="chevron-left-back-mobile"></Icon>-->
<!--          </div>-->
<!--          <div class="flex flex-row justify-center w-full">-->
<!--            <div class="registration-header">Регистрация</div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

      <UIPageHeader class="md:hidden mb-[56px]" title="Регистрация">
        <template #search>
          <div></div>
        </template>
      </UIPageHeader>
      <div class="hidden md:block mb-[32px] registration-header ">Регистрация</div>

      <ButtonNew @click="router.push('/signup/employee')" type="stroke-medium">Я соискатель</ButtonNew>
      <div class="flex flex-row justify-start w-full mt-[8px] md:mb-[30px] mb-[2.28rem]">
        <div class="md:text-[12px] text-[0.87rem] font-light text-[#7B7A7A] md:text-[#1F1E1E]">хочу найти работу
        </div>
      </div>

      <ButtonNew @click="router.push('/signup/employer')" type="stroke-medium">Я работодатель</ButtonNew>
      <div class="flex flex-row justify-start w-full mt-[8px]  md:mb-[30px] mb-[2.28rem]">
        <div class="md:text-[12px] text-[0.87rem] font-light text-[#7B7A7A] md:text-[#1F1E1E]">хочу разместить
          вакансию
        </div>
      </div>

      <ButtonNew @click="router.push('/signup/student')" type="stroke-medium">Я студент</ButtonNew>
      <div class="flex flex-row justify-start w-full mt-[8px]  md:mb-[30px] mb-[2.28rem]">
        <div class="md:text-[12px] text-[0.87rem] font-light text-[#7B7A7A] md:text-[#1F1E1E]">хочу учавствовать в стажировках и найти работу
        </div>
      </div>
    </authTemplateCard>
    <!--    <FooterNew></FooterNew>-->
  </DefaultLayout>

</template>


<script setup>
import ButtonNew from '@/components/ui/ButtonNew.vue';
import authTemplateCard from '@/components/cards/authTemplateCard.vue';
import Icon from '@/components/ui/Icon.vue';
import {ref, reactive, watch, onUnmounted, onMounted, computed} from 'vue';
import {useRoute} from 'vue-router';
import HeaderNew from '@/components/ui/HeaderNew.vue';
import FooterNew from '@/components/ui/FooterNew.vue';
import router from '@/router';
import GetSupportCard from './../components/cards/GetSupportCard.vue'
import RegistrationCard from './../components/cards/RegistrationCard.vue'
import EmployeRegistrationBackground from '@/assets/images/signup_background_employee.png';
import EmployerRegistrationBackground from '@/assets/images/signup_background_employer.png';
import DefaultLayout from "@/layouts/DefaultLayout";
import UIPageHeader from "@/components/ui/UIPageHeader";


const route = useRoute();

const disableScroll = ref(false);

const handleToggleScroll = () => {
  disableScroll.value = !disableScroll.value;
};

watch(disableScroll, (newValue) => {
  if (newValue) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

const showPopup = ref(false);

const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
  isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
  window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile);
});


</script>

<style></style>