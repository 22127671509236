<template>
    <div :class=standardClass>
        <div class="flex flex-row justify-center h-full items-end">
            <div
                class="flex flex-col bg-[#031722] md:h-[356px] h-full pt-[26px] pb-[30px] justify-between md:items-center w-full">

                <div v-if="!isMobile" class="flex flex-row justify-between w-[992px] mb-[-65px]">

                    <div class="flex flex-col">
                        <router-link to="/">
                            <img src="./../../assets/images/logo.webp"
                                class="w-[194px] h-[79px] object-cover mb-[12px]">
                        </router-link>
                        <div class="text-[12px] leading-[14.68px] text-white w-[194px] mb-[45px]">
                            {{ staticStore.adress }}
                        </div>
                        <div class="text-white text-[24px] leading-[29.35px] mb-[6px]">
                            {{ staticStore.support.phone }}
                        </div>
                        <div class="text-white text-[20px] leading-[24.46px] mb-[17px]">
                            {{ staticStore.support.email }}
                        </div>
                        <div class="flex flex-row gap-x-[27px]">
                            <a :href="'https://wa.me/' + staticStore.support.whatsapp" target="_blank">
                                <UIIcon name="whatsApp" class="mt-[-3px]" size="32" color="#0A5CD5" />
                            </a>
                            <a :href="'https://t.me/' + staticStore.support.telegram" target="_blank">
                                <div class="relative rounded-full  w-[28px] h-[28px] bg-transparent">
                                    <UIIcon class="absolute top-[-3px] left-[-3px]" name="telegramAlt" size="32" />
                                </div>
                            </a>
                            

                            <a href="https://vk.com/bvvru" target="_blank">
                                <div class="relative rounded-full bg-transparent w-[28px] h-[28px]">
                                    <UIIcon class="absolute top-[-3px] left-[-3px]" name="vk" size="32" />
                                </div>
                            </a>

                        </div>

                    </div>

                    <div class="flex flex-col">
                        <div class="flex flex-row mb-[135px]">
                            <div class="flex flex-col mr-[107px]">
                                <router-link to="/employee/vacancies" @click="sendMetrick('EMPLOYEE-FOOTER')">
                                    <div class="footer-menu-item">Соискателям</div>
                                </router-link>

                                <router-link to="/employer/resumes" @click="sendMetrick('EMPLOYER-FOOTER')">
                                    <div class="footer-menu-item">Работодателям</div>
                                </router-link>

                                <router-link to="/students" @click="sendMetrick('STUDENTS-FOOTER')">
                                    <div class="footer-menu-item">Студентам</div>
                                </router-link>

                                <router-link to="/education/articles" @click="sendMetrick('EDUCATION-FOOTER')">
                                    <div class="footer-menu-item">Обучающая платформа</div>
                                </router-link>

                                <router-link to="/education/events" @click="sendMetrick('EVENTS-FOOTER')">
                                    <div class="footer-menu-item">Мероприятия</div>
                                </router-link>

                            </div>

                            <div class="flex flex-col mr-[95px]">
                                <router-link to="/">
                                    <div class="footer-menu-item">Партнерская программа</div>
                                </router-link>

                                <router-link to="/">
                                    <div class="footer-menu-item">О компании</div>
                                </router-link>

                                <router-link to="/employee/vacancies" @click="sendMetrick('VACANCIES-FOOTER')">
                                    <div class="footer-menu-item">Все вакансии</div>
                                </router-link>

                            </div>

                            <div class="flex flex-col">
                                <router-link to="/">
                                    <div class="footer-menu-item">Тех. поддержка</div>
                                </router-link>

                                <router-link to="/">
                                    <div class="footer-menu-item">FAQ</div>
                                </router-link>

                            </div>

                        </div>
                        <div class="flex flex-row justify-between w-[670px]">
                            <div class="footer-menu-item-down">
                                Юридическая информация
                            </div>
                            <div class="footer-menu-item-down">
                                Договор оферты
                            </div>
                            <div class="footer-menu-item-down">
                                Политика конфиденциальности
                            </div>
                        </div>
                    </div>

                </div>
                <div v-else class="flex-col footer">
                    <div class="flex flex-row w-full justify-center mb-[1rem]">
                        <div class="flex flex-row justify-between items-center w-[64%]">
                            <a :href="'https://t.me/' + staticStore.support.telegram" target="_blank">
                                <div class="relative rounded-full bg-white w-[28px] h-[28px]">
                                    <UIIcon class="absolute top-[-3px] left-[-3px]" name="telegram" size="32" />
                                </div>
                            </a>
                            <div class="text-[12px] leading-[14.68px] text-white w-[11.7rem]">
                                {{ staticStore.adress }}
                            </div>

                        </div>
                    </div>
                    <div class="flex flex-row w-full justify-center mb-[1rem]">
                        <div class="flex flex-row justify-between items-center w-[64%]">
                            <a :href="'https://wa.me/' + staticStore.support.whatsapp" target="_blank">
                                <UIIcon name="whatsApp" class="ml-[-0.1rem]" size="32" />
                            </a>
                            <div class="text-[1.12rem] leading-[1.37rem] text-white w-[11.7rem]">
                                {{ staticStore.support.phone }}
                            </div>

                        </div>
                    </div>
                    <div class="flex flex-row w-full justify-center mb-[20px]">
                        <div class="flex flex-row justify-between items-center w-[64%]">
                            <a :href="'https://t.me/' + staticStore.support.telegram" target="_blank">
                                <div class="relative rounded-full bg-white w-[28px] h-[28px]">
                                    <UIIcon class="absolute top-[-3px] left-[-3px]" name="telegram" size="32" />
                                </div>
                            </a>
                            <div class="text-[12px] leading-[14.68px] text-white w-[11.7rem] mr-[-0.1rem]">
                                {{ staticStore.support.email }}
                            </div>

                        </div>
                    </div>
                    <div class="flex flex-col px-[1rem]">
                        <div class="border border-white border-[1px] mb-[18px]"></div>
                        <div class="flex flex-row justify-between mb-[32px]">
                            <div class="flex-flex-col gap-y-[12px]">
                                <router-link to="/employee" @click="sendMetrick('EMPLOYEE-FOOTER')">
                                    <div class="footer-menu-item">Соискателям</div>
                                </router-link>

                                <router-link to="/employer" @click="sendMetrick('EMPLOYER-FOOTER')">
                                    <div class="footer-menu-item">Работодателям</div>
                                </router-link>

                                <router-link to="/students" @click="sendMetrick('STUDENTS-FOOTER')">
                                    <div class="footer-menu-item">Студентам</div>
                                </router-link>

                                <router-link to="/education/articles" @click="sendMetrick('EDUCATION-FOOTER')">
                                    <div class="footer-menu-item">Обучающая платформа</div>
                                </router-link>

                                <router-link to="/education/events" @click="sendMetrick('EVENTS-FOOTER')">
                                    <div class="footer-menu-item">Мероприятия</div>
                                </router-link>
                            </div>
                            <div class="flex-flex-col gap-y-[12px]">
                                <router-link to="/">
                                    <div class="footer-menu-item">Партнерская программа</div>
                                </router-link>

                                <router-link to="/">
                                    <div class="footer-menu-item">О компании</div>
                                </router-link>

                                <router-link to="/employee/vacancies" @click="sendMetrick('VACANCIES-FOOTER')">
                                    <div class="footer-menu-item">Все вакансии</div>
                                </router-link>

                                <router-link to="/">
                                    <div class="footer-menu-item">Тех. поддержка</div>
                                </router-link>

                                <router-link to="/">
                                    <div class="footer-menu-item">FAQ</div>
                                </router-link>
                            </div>
                        </div>
                        <div class="footer-menu-item-down">
                            Политика конфиденциальности
                        </div>
                        <div class="flex flex-row">
                            <div class="footer-menu-item-down">
                                Юридическая информация
                            </div>
                            <div class="footer-menu-item-down">
                                Договор оферты
                            </div>
                        </div>

                        <!-- <div class="footer-menu-item-down">
                            Договор оферты
                        </div>
                        <div class="footer-menu-item-down">
                            Политика конфиденциальности
                        </div> -->
                    </div>
                </div>







            </div>
        </div>

    </div>

</template>

<script setup>
import { computed } from 'vue';
import { defineProps, defineEmits, ref, onMounted, onUnmounted } from 'vue';
import { sendMetrick } from '@/composables/utils';
import { useStaticStore } from '@/stores/staticStore';
import UIIcon from './UIIcon.vue';
import Icon from './Icon.vue';

const staticStore = useStaticStore();

const standardClass = "w-full h-full";
const props = defineProps({
    class: {
        type: String,
        default: ''
    },
});


const isMobile = ref(window.innerWidth <= 768);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768;
};

onMounted(() => {
    window.addEventListener('resize', updateIsMobile);
});

onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
});


</script>

<style scoped>
/* @media (min-width: 1800px) {
    .footer {
        padding-right: 0px;
        padding-left: 0px;
    }
} */

/* @media (max-width: 768px) {
    .footer {
        padding-right: 1rem;
        padding-left: 1rem;
    }
} */

.footer-menu-item {
    @apply text-[0.85rem] md:text-[14px] md:mb-[12px] leading-[1rem] md:leading-[17.2px] text-[#FFFFFF] mb-[0.85rem] cursor-pointer;
    font-weight: 400;
}

.footer-menu-item-down {
    @apply text-[0.85rem] md:text-[12px] mb-[0.5rem] md:mb-0 leading-[1rem] md:leading-[14px] md:text-[#FFFFFF] text-[#B7B7B7] cursor-pointer mr-[0.5rem];
    font-weight: 300;
}
</style>