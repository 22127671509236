<template>
    <Sheet
        class="!p-0  w-full  flex flex-col shadow md:h-[283px] w-full min-h-[13.75rem] bg-white md:rounded-[24px] rounded-[12px] cursor-pointer" @click="handleClick">
        <div
            class="flex flex-row items-center justify-between md:rounded-t-[24px] rounded-t-[12px] bg-[#0A5CD51A] h-[40px] md:px-[24px] px-[0.8rem]">
            <div class="text-[#0A5CD5] font-medium md:text-[16px] text-[14px]">Стажировка</div>
            <div class="text-[#1F1E1E] font-light text-[12px]">
                {{ formatDateTime(internship.created_at) }}</div>
        </div>
        <div class="flex flex-col md:px-[24px] px-[0.8rem] gap-y-2 md:mt-[24px] mt-[12px]">
            <div class="flex flex-row w-full justify-between">
                <div
                    class="md:text-[24px] md:leading-[28px] md:font-normal font-medium text-[16px] leading-[20px] md:w-full w-[85%]">
                    {{ nameCutted(internship.name) }}</div>
            </div>

            <div class="hidden md:block text-[14px] leading-[17px]">Период стажировки: c {{
                formatDateMinimal(internship.internship_date_from) }} по {{
                    formatDateMinimal(internship.internship_date_to) }}</div>
            <div class="md:hidden text-[14px] leading-[17px]">Период: c {{
                formatDateMinimal(internship.internship_date_from, true) }} по {{
                    formatDateMinimal(internship.internship_date_to, true) }}</div>
            <div v-if="!internship.employment"
                class="text-[#7B7A7A] md:text-[14px] md:leading-[25px] text-[12px] leading-[21px]">
                Без
                трудоустройства</div>
            <div v-else
                class="text-[#7B7A7A] px-2 md:text-[14px] md:leading-[25px] text-[12px] leading-[21px] bg-[#FFDA0729] inline-block w-min"
                style="white-space: nowrap;">
                С трудоустройством
            </div>
        </div>
        <div class="flex flex-col md:px-[24px] px-[0.8rem]">
            <div class="font-medium md:mt-[18px] mt-[12px] md:mb-[24px] mb-[12px]">{{
                internship.city }}</div>
            <div class="flex flex-row-reverse w-full">
                <div class="!w-[50%]">
                    <ButtonNew v-if="!authStore.isAuthenticated || authStore.role === 'employee'"
                        @click.stop="handleResponseClick(internship.id)" class="!h-[34px] md:!h-[40px]">
                        Подать заявку
                    </ButtonNew>
                </div>
            </div>
        </div>

    </Sheet>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import ButtonNew from '@/components/ui/ButtonNew.vue';
import {
  formatPrice,
  formatDateTime,
  formatDateTimeVacancyCreated,
  formatexperienceForVacancy,
  formatDateMinimal,
  nameCutted,
  sendMetrick,
  humanizeDate
} from '@/composables/utils';
import { usePopupsStore } from '@/stores/popupsStore';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import Sheet from './Sheet.vue';
const props = defineProps({
    internship: {
        type: Object,
        required: true
    }
})

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const popupsStore = usePopupsStore()

const handleResponseClick = (vacancyId) => {
    if (route.path === '/')
        sendMetrick('MAIN-PAGE-VACANCY-RESPONSE')
    else if (route.path === '/employee/vacancies') {
        sendMetrick('EMPLOYEE-PAGE-VACANCY-RESPONSE')
    }
    popupsStore.openResponsePopup(vacancyId, router)
}

const handleClick = () => {
    router.push(`/employee/vacancies/${props.internship.id}`)
}
</script>

<style scoped></style>