<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white"/>
<rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#FF3B30"/>
<path d="M10.1464 10.8536C9.95118 10.6583 9.95118 10.3417 10.1464 10.1464C10.3417 9.95118 10.6583 9.95118 10.8536 10.1464L16 15.2929L21.1464 10.1464C21.3417 9.95118 21.6583 9.95118 21.8536 10.1464C22.0488 10.3417 22.0488 10.6583 21.8536 10.8536L16.7071 16L21.8536 21.1464C22.0488 21.3417 22.0488 21.6583 21.8536 21.8536C21.6583 22.0488 21.3417 22.0488 21.1464 21.8536L16 16.7071L10.8536 21.8536C10.6583 22.0488 10.3417 22.0488 10.1464 21.8536C9.95119 21.6583 9.95119 21.3417 10.1464 21.1464L15.2929 16L10.1464 10.8536Z" fill="#FF3B30"/>
</svg>







</template>

<script setup>

</script>

<style scoped></style>