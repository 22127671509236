<template>
    <div   @click="click"
        class="flex flex-row shadow md:w-[655px] md:h-[263px] w-full h-[13.37rem] bg-white md:rounded-[24px] rounded-[12px] cursor-pointer">
        <div
            class="md:!w-[40px] w-[2rem] h-full bg-[#01B00A] rounded-l-[12px] md:rounded-l-[24px] flex items-center justify-center">
            <div class="vertical-text text-white text-[14px] leading-[17.12px]">Студент</div>
        </div>
        <div class="flex flex-col w-full md:pl-[16px] md:pr-[24px] md:py-[24px] p-[16px] pl-[8px]">
            <div class="flex flex-row justify-between">
                <div class="flex flex-row w-full md:gap-x-[20px] gap-x-[8px]">
                    <div v-if="resume?.employee_avatar " class="w-[80px]">
                        <img :src="`${baseURL}/files/${resume.employee_avatar}/download`"
                            class="!w-[80px] !h-[80px] object-cover rounded-full">
                    </div>

                    <div v-else>
                        <UIIcon size="80" name="personCircled"></UIIcon>
                    </div>


                    <div v-if="resume?.speciality_name"
                        class="md:text-[20px] md:leading-[24.46px] text-[14px] leading-[17.12px] font-medium md:max-w-[339px] md:min-w-[330px] w-[7.81rem]">
                        {{ resume.speciality_name.length > 50 ? resume.speciality_name.slice(0, 50) + '...' : resume.speciality_name }}
                    </div>
                    <div v-else
                        class="text-[#7B7A7A] md:text-[20px] md:leading-[24.46px] text-[14px] leading-[17.12px] font-medium md:max-w-[339px] md:min-w-[330px] w-[7.81rem]">
                        Не указано
                    </div>
                </div>
                <slot name="right-up">
                    <div class="hidden md:block text-right md:text-[12px] md:leading-[13px] text-[10px] leading-[12.22px] font-light text-[#7B7A7A] whitespace-nowrap">
                        {{ formatDateTimeResumeCreated(resume?.created_at) }}</div>
                </slot>
                
            </div>

            <div class="hidden md:block w-[284px] flex flex-col gap-y-[10px] mt-[17px]">
                <div class="flex flex-row justify-between">
                    <div class="text-[#7B7A7A] text-[14px] leading-[17px]">ВУЗ</div>
                    <div v-if="resume?.education" class="text-[14px] leading-[17px]">{{ resume.education.institution_name}}</div>
                    <div v-else class="text-[14px] leading-[17px] text-[#7B7A7A]">не указано</div>
                </div>
                <div class="flex flex-row justify-between">
                    <div class="text-[#7B7A7A] text-[14px] leading-[17px]">Период обучения</div>
                    <div v-if="resume?.education" class="text-[14px] leading-[17px]">{{ resume.education.end_year }}</div>
                    <div v-else class="text-[14px] leading-[17px] text-[#7B7A7A]">не указано</div>
                </div>
                <div class="flex flex-row justify-between">
                    <div class="text-[#7B7A7A] text-[14px] leading-[17px]">Город</div>
                    <div class="text-[14px] leading-[17px]">{{ utils.emptySC(resume?.city_of_residence) }}</div>
                </div>
            </div>
            <div class="md:hidden mt-[16px]">
                <div class="flex flex-row justify-between">
                    <div v-if="resume?.education" class="text-[14px]">{{ resume?.education.institution_name}}</div>
                    <div v-else class="text-[14px] text-[#7B7A7A]">не указано</div>
                    <div class="text-[16px] font-medium">{{ utils.emptySC(resume?.city_of_residence) }}</div>
                </div>
                <div v-if="resume?.education" class="mt-12px text-[14px]">{{ resume?.education.end_year}}</div>
                <div v-else class="mt-12px text-[14px]">не указано</div>

            </div>
            <div class="md:mt-4 mt-1 w-full">
                <slot name="action">

                </slot>
            </div>

            <!-- <div
                class="md:text-[14px] md:leading-[17.12px] font-medium md:h-[17.12px]  md:mt-[8px] mt-[4px] h-[14.68px] text-[12px] leading-[14.68px]">
                {{ user.city_of_residence }}</div>
            <div class="flex flex-row h-full justify-between items-end">
                <div class="md:text-[12px] md:leading-[13px] text-[10px] leading-[12.22px] font-light text-[#7B7A7A]">
                    сегодня в 11:31</div>
                <ButtonNew v-if="authStore.role === 'employer'"
                    class="md:!text-[14px] text-[12px] !rounded-[12px] md:!h-[40px] h-[32px] !w-[150px]">Пригласить
                </ButtonNew>
            </div> -->
        </div>

    </div>
</template>
<script setup>
import { defineProps } from "vue"
import UIIcon from "../ui/UIIcon.vue";
import { useFetch } from "@/composables/useFetch";
import { useMobile } from "@/composables/useMobile";
import ButtonNew from "../ui/ButtonNew.vue";
import { useAuthStore } from "@/stores/authStore";
import { useUtils } from "@/composables/useUtils";
import { formatDateTimeResumeCreated } from '@/composables/utils';
import { useRouter } from "vue-router";


const router = useRouter();
const utils = useUtils();

const authStore = useAuthStore();
const mobile = useMobile()
const { baseURL } = useFetch();

const props = defineProps({
    resume: {
        default: null
    },
    clickable: {
        default: true
    }
});

const click = () => {
    if (!props.clickable)
        return
    if (props.resume)
        router.push(`/employer/resumes/${props.resume?.id}`)
}
</script>
<style scoped>
.shadow {
    box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
}

.shadow:hover {
    outline: 1px solid #0A5CD5; /* Используйте outline вместо border */
}

.vertical-text {
    writing-mode: vertical-rl;
    /* Вертикальная ориентация текста */
    transform: rotate(180deg);
    /* Поворачиваем текст для начала снизу */
    text-align: center;
    /* Выравнивание текста по центру */
    margin: 0;
    /* Отмена отступов по умолчанию */
}
</style>