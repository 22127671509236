<template>
    <DefaultLayout>
        <div class="flex flex-row justify-center ml-1">
            <UIPageHeader v-if="mobile.isMobile.value" title="Стажировки"
                search-placeholder="Профессия, должность или компания" @search="fetchVacanciesDataFiltered()"
                v-model:search-value="searchTerm">

                <template #back>
                    <div></div>
                </template>

                <template #append>
                    <div class="flex gap-[24px] ">
                        <div v-if="pageMode === 'filter'" class="cursor-pointer flex-shrink-0"
                            @click="actionSheets.openFiltersSheet(applyFiltersCallback)">
                            <img src="/icons/sliders-24-blue.svg" class="!w-[18px] !h-[18px] flex-shrink-0">
                        </div>
                        <div class="cursor-pointer flex-shrink-0"
                            @click="actionSheets.openOrderingSheet(applyOrderingCallback)">
                            <img src="/icons/arrow-down-up-24-blue.svg" class="!w-[18px] !h-[18px] flex-shrink-0">
                        </div>
                    </div>

                </template>
            </UIPageHeader>
        </div>

        <div class="flex flex-row justify-center w-full">
            <div class="flex flex-col md:w-[992px] w-full p-3 md:p-0">
                <div class="flex flex-row justify-between items-center md:h-[53px] h-[22px] w-full md:mt-[53px] mt-2">
                    <div class="flex flex-row justify-between items-center md:w-[300px] w-[49%]">
                        <div class="cursor-pointer" @click="setPageMode('internships')"
                            :class="pageMode === 'internships' ? 'md:text-[24px] text-[16px] font-medium text-[#0A5CD5]' : 'md:text-[20px] text-[14px] font-normal text-[#7B7A7A]'">
                            Стажировки</div>
                        <div class="cursor-pointer" @click="setPageMode('vacancies')"
                            :class="pageMode === 'vacancies' ? 'md:text-[24px] text-[16px] font-medium text-[#0A5CD5]' : 'md:text-[20px] text-[14px] font-normal text-[#7B7A7A]'">
                            Вакансии</div>

                    </div>
                    <div class="cursor-pointer" @click="setPageMode('filter')"
                        :class="pageMode === 'filter' ? 'md:text-[24px] text-[16px] font-medium text-[#0A5CD5]' : 'md:text-[20px] text-[14px] font-normal text-[#7B7A7A]'">
                        Фильтр</div>
                </div>
                <div v-if="!mobile.isMobile.value" class="flex flex-row w-full gap-[12px] mt-[40px]">
                    <Input v-model="searchTerm" inputClass=" !h-[40px]"></Input>
                    <div class="!w-[150px] flex-shrink-0">
                        <ButtonNew class=" !h-[40px]">Найти</ButtonNew>
                    </div>

                </div>
                <div
                    class="flex md:flex-wrap md:flex-row flex-col md:justify-between w-full md:gap-y-[20px] gap-y-[1rem] mt-[20px] mb-[40px]">
                    <a class="md:!w-[49%] w-full" v-for="(item, index) in vacancies" :key="index"
                        v-if="pageMode !== 'filter'">

                        <VacancyCardNew v-if="pageMode === 'vacancies'" :vacancyId=item.id :vacancyName=item.name
                            vacancyNameLength=55 :employerName=item.employer_name :tax=item.price_tax
                            :employerAvatar="item.employer_avatar" :priceFrom=item.price_from :priceTo=item.price_to
                            :currencyName=item.currency_display_name :city=item.city :publicationTime=item.created_at
                            :experience=item.experience>
                        </VacancyCardNew>
                        <InternshipCard v-else-if="pageMode === 'internships'" :internship="item" class="w-full"></InternshipCard>
                        <!-- <div v-else-if="pageMode === 'internships'"
                            class="flex flex-col shadow md:h-[283px] w-full min-h-[13.75rem] bg-white md:rounded-[24px] rounded-[12px]">
                            <div
                                class="flex flex-row items-center justify-between md:rounded-t-[24px] rounded-t-[12px] bg-[#0A5CD51A] h-[40px] md:px-[24px] px-[0.8rem]">
                                <div class="text-[#0A5CD5] font-medium md:text-[16px] text-[14px]">Стажировка</div>
                                <div class="text-[#1F1E1E] font-light text-[12px]">
                                    {{ formatDateTime(item.created_at) }}</div>
                            </div>
                            <div class="flex flex-col md:px-[24px] px-[0.8rem] gap-y-2 md:mt-[24px] mt-[12px]">
                                <div class="flex flex-row w-full justify-between">
                                    <div
                                        class="md:text-[24px] md:leading-[28px] md:font-normal font-medium text-[16px] leading-[20px] md:w-full w-[85%]">
                                        {{ nameCutted(item.name) }}</div>
                                </div>

                                <div class="hidden md:block text-[14px] leading-[17px]">Период стажировки: c {{
                                    formatDateMinimal(item.internship_date_from) }} по {{
                                        formatDateMinimal(item.internship_date_to) }}</div>
                                <div class="md:hidden text-[14px] leading-[17px]">Период: c {{
                                    formatDateMinimal(item.internship_date_from, true) }} по {{
                                        formatDateMinimal(item.internship_date_to, true) }}</div>
                                <div v-if="!item.employment"
                                    class="text-[#7B7A7A] md:text-[14px] md:leading-[25px] text-[12px] leading-[21px]">
                                    Без
                                    трудоустройства</div>
                                <div v-else
                                    class="text-[#7B7A7A] px-2 md:text-[14px] md:leading-[25px] text-[12px] leading-[21px] bg-[#FFDA0729] inline-block w-min"
                                    style="white-space: nowrap;">
                                    С трудоустройством
                                </div>
                            </div>
                            <div class="flex flex-col md:px-[24px] px-[0.8rem]">
                                <div class="font-medium md:mt-[18px] mt-[12px] md:mb-[24px] mb-[12px]">{{
                                    item.city }}</div>
                                <div class="flex flex-row-reverse w-full">
                                    <div class="md:!w-[50%] w-full">
                                        <ButtonNew v-if="!authStore.isAuthenticated || authStore.role === 'employee'"
                                            @click="handleResponseClick(item.id)" class="!h-[34px] md:!h-[40px]">
                                            Подать заявку
                                        </ButtonNew>
                                    </div>
                                </div>
                            </div>

                        </div> -->
                    </a>
                    <div v-if="pageMode === 'filter'" class="flex flex-row gap-[20px] w-full">
                        <FiltersDesktop @clearFilters="onClearFilters" @applyFilters="fetchVacanciesDataFiltered"
                            v-if="!mobile.isMobile.value" v-model="filters"></FiltersDesktop>

                        <div class="flex flex-col md:max-w-[655px] w-full">
                            <div v-if="!mobile.isMobile.value"
                                class="flex flex-row gap-[32px] items-center mb-[32px] w-full">
                                <div class="">Сортировать по</div>
                                <div class="w-[300px]">
                                    <UISelectNew ref="uiSelect" v-model="ordering" :options="options"
                                        triggerClass="!h-[32px]">
                                        <template #dropdown>
                                            <ul
                                                class="bg-white border-[1px] border-[#0A5CD5] rounded-b-[24px] shadow-lg w-full h-[220px]">
                                                <li v-for="(option, index) in options" :key="index"
                                                    class="px-4 py-2 hover:bg-gray-100 text-[14px] h-[48px] cursor-pointer w-full flex flex-row justify-between items-center"
                                                    @click="selectOption(option)">
                                                    <div>{{ option.title }}</div>
                                                    <UIRadioButton :model-value="option.value === ordering"
                                                        :value="true">
                                                    </UIRadioButton>
                                                </li>

                                            </ul>


                                        </template>
                                    </UISelectNew>
                                </div>
                            </div>
                            <div v-for="(item, index) in vacancies" :key="index" class="mb-[20px] w-full">
                                <VacancyCardNew v-if="item.employment_type !== 'internship'" :vacancyId=item.id
                                    :vacancyName=item.name vacancyNameLength=55 :employerName=item.employer_name
                                    :tax=item.price_tax :employerAvatar="item.employer_avatar"
                                    :priceFrom=item.price_from :priceTo=item.price_to
                                    :currencyName=item.currency_display_name :city=item.city
                                    :publicationTime=item.created_at :experience=item.experience>
                                </VacancyCardNew>
                                <InternshipCard v-else :internship="item" class="w-full"></InternshipCard>
                                 <!-- <div v-else
                                    class="flex flex-col shadow md:h-[283px] w-full min-h-[13.75rem] bg-white md:rounded-[24px] rounded-[12px]">
                                    <div
                                        class="flex flex-row items-center justify-between md:rounded-t-[24px] rounded-t-[12px] bg-[#0A5CD51A] h-[40px] md:px-[24px] px-[0.8rem]">
                                        <div class="text-[#0A5CD5] font-medium md:text-[16px] text-[14px]">Стажировка
                                        </div>
                                        <div class="text-[#1F1E1E] font-light text-[12px]">
                                            {{ formatDateTime(item.created_at) }}</div>
                                    </div>
                                    <div class="flex flex-col md:px-[24px] px-[0.8rem] gap-y-2 md:mt-[24px] mt-[12px]">
                                        <div class="flex flex-row w-full justify-between">
                                            <div
                                                class="md:text-[24px] md:leading-[28px] md:font-normal font-medium text-[16px] leading-[20px] md:w-full w-[85%]">
                                                {{ nameCutted(item.name) }}</div>
                                        </div>

                                        <div class="hidden md:block text-[14px] leading-[17px]">Период стажировки: c {{
                                            formatDateMinimal(item.internship_date_from) }} по {{
                                                formatDateMinimal(item.internship_date_to) }}</div>
                                        <div class="md:hidden text-[14px] leading-[17px]">Период: c {{
                                            formatDateMinimal(item.internship_date_from, true) }} по {{
                                                formatDateMinimal(item.internship_date_to, true) }}</div>
                                        <div v-if="!item.employment"
                                            class="text-[#7B7A7A] md:text-[14px] md:leading-[25px] text-[12px] leading-[21px]">
                                            Без
                                            трудоустройства</div>
                                        <div v-else
                                            class="text-[#7B7A7A] px-2 md:text-[14px] md:leading-[25px] text-[12px] leading-[21px] bg-[#FFDA0729] inline-block w-min"
                                            style="white-space: nowrap;">
                                            С трудоустройством
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:px-[24px] px-[0.8rem]">
                                        <div class="font-medium md:mt-[18px] mt-[12px] md:mb-[24px] mb-[12px]">{{
                                            item.city }}</div>
                                        <div class="flex flex-row-reverse w-full">
                                            <div class="md:!w-[50%] w-full">
                                                <ButtonNew
                                                    v-if="!authStore.isAuthenticated || authStore.role === 'employee'"
                                                    @click="handleResponseClick(item.id)"
                                                    class="!h-[34px] md:!h-[40px]">
                                                    Подать заявку
                                                </ButtonNew>
                                            </div>
                                        </div>
                                    </div>

                                </div> -->
                            </div>
                            <div class="flex justify-center md:block">
                                <UIPagination class="mb-[50px] md:mt-[33px]" v-if="pagination.totalPages > 0"
                                    :total-pages="pagination.totalPages" :current-page="pagination.page"
                                    @page-changed="pagination.page = $event">
                                </UIPagination>

                            </div>
                        </div>


                    </div>
                </div>
                <div class="flex flex-col w-full justify-center" v-if="pageMode !== 'filter'">
                    <div v-if="!authStore.isAuthenticated" class="flex flex-row w-full justify-center mb-[40px]">
                        <ButtonNew class=" !rounded-[100px] !h-[44px] md:!w-[308px] !w-[90%]" type="stroke"
                            @click="router.push('/signup')">
                            Зарегистрироваться
                        </ButtonNew>
                    </div>
                    <Sheet>
                        <div
                            class="md:text-[24px] md:leading-[29px] text-[18px] leading-[22px] text-[#0A5CD5] mb-[12px] font-medium">
                            Покажи
                            свою
                            экспертность перед лидерами рынка</div>
                        <div class="text-[14px] leading-[17.12px] mb-[20px]">Предлагаем вам написать статьи о ваших
                            проектах
                            и
                            дать возможность зарекомендовать себя перед компаниями</div>
                        <div
                            class="md:text-[24px] md:leading-[29.35px] md:font-normal text-[18px] leading-[22px] font-medium text-[#0A5CD5] md:mb-5 mb-[16px]">
                            Статьи</div>
                        <div v-if="articles.items[0]" class="flex md:flex-row flex-col">
                            <div class="md:!w-[50%]">
                                <ArticleIndexCard :article="articles.items[0]"></ArticleIndexCard>
                            </div>
                            <div class="flex justify-center items-center md:w-[50%] text-[#0A5CD5] cursor-pointer hover:underline md:mt-0 md:mb-0 mt-[40px] mb-[40px]"
                                @click="router.push('/education/articles')">
                                <div class="md:text-[16px] text-[12px]">Посмотреть все статьи</div> <svg class="ml-2"
                                    width="161" height="9" viewBox="0 0 161 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M160.854 4.85355C161.049 4.65829 161.049 4.34171 160.854 4.14645L157.672 0.964466C157.476 0.769204 157.16 0.769204 156.964 0.964466C156.769 1.15973 156.769 1.47631 156.964 1.67157L159.793 4.5L156.964 7.32843C156.769 7.52369 156.769 7.84027 156.964 8.03553C157.16 8.2308 157.476 8.2308 157.672 8.03553L160.854 4.85355ZM0.5 5H160.5V4H0.5V5Z"
                                        fill="#0A5CD5" />
                                </svg>

                            </div>
                        </div>
                        <div
                            class="md:text-[24px] md:leading-[29.35px] md:font-normal text-[18px] leading-[22px] font-medium text-[#0A5CD5] md:mb-5 mb-[16px] mt-5">
                            Мероприятия</div>
                        <div v-if="events.items[0]" class="flex md:flex-row flex-col-reverse">

                            <div class="flex justify-center items-center md:w-[50%] text-[#0A5CD5] cursor-pointer hover:underline md:mt-0 md:mb-0 mt-[40px] mb-[40px]"
                                @click="router.push('/education/events')">
                                <svg class="mr-2" width="161" height="9" viewBox="0 0 161 9" fill="none"
                                    xmlns="http://www.w3.org/2000/svg" transform="scale(-1, 1)">
                                    <path
                                        d="M160.854 4.85355C161.049 4.65829 161.049 4.34171 160.854 4.14645L157.672 0.964466C157.476 0.769204 157.16 0.769204 156.964 0.964466C156.769 1.15973 156.769 1.47631 156.964 1.67157L159.793 4.5L156.964 7.32843C156.769 7.52369 156.769 7.84027 156.964 8.03553C157.16 8.2308 157.476 8.2308 157.672 8.03553L160.854 4.85355ZM0.5 5H160.5V4H0.5V5Z"
                                        fill="#0A5CD5" />
                                </svg>
                                <div class="md:text-[16px] text-[12px]">Посмотреть все мероприятия</div>


                            </div>
                            <div class="md:!w-[50%]">
                                <EventIndexCard :event="events.items[0]"></EventIndexCard>
                            </div>
                        </div>
                    </Sheet>
                    <div class="mb-[40px]"></div>
                    <div v-if="!authStore.isAuthenticated"
                        class="flex flex-row w-full justify-center mt-[40px] mb-[65px]">
                        <ButtonNew class=" !rounded-[100px] !h-[44px] md:!w-[308px] !w-[90%]" type="stroke"
                            @click="router.push('/signup')">
                            Зарегистрироваться
                        </ButtonNew>
                    </div>

                </div>

            </div>
        </div>

    </DefaultLayout>
</template>
<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import { ref, reactive, watch, onMounted, onUnmounted, computed, onBeforeMount } from 'vue';
import Input from '@/components/ui/Input.vue';
import ButtonNew from '@/components/ui/ButtonNew.vue';
import VacancyCardNew from '@/components/cards/VacancyCardNew.vue';
import { useFetch } from '@/composables/useFetch';
import { usePopupsStore } from '@/stores/popupsStore';
import { useRouter } from 'vue-router';
import Sheet from '@/components/cards/Sheet.vue';
import {
    formatDateTime, formatexperienceForVacancy, formatexperienceForVacancyNew,
    sendMetrick, formatPrice, formatDate, formatDateMinimal, nameCutted
} from '@/composables/utils';
import { useAuthStore } from '@/stores/authStore';
import ArticleIndexCard from '@/components/cards/ArticleIndexCard.vue';
import EventIndexCard from '@/components/cards/EventIndexCard.vue';
import { useArticlesStore } from '@/stores/articlesStore';
import { useEventsStore } from '@/stores/eventsStore';
import FiltersDesktop from '@/components/cards/FiltersDesktop.vue';
import UISelectNew from '@/components/ui/UISelectNew.vue';
import { useMobile } from '@/composables/useMobile';
import InternshipCard from '@/components/cards/InternshipCard.vue';
import UIPageHeader from '@/components/ui/UIPageHeader.vue';
import { useActionSheetsStore } from '@/stores/actionSheets';
import UIPagination from '@/components/ui/UIPagination.vue';

const actionSheets = useActionSheetsStore();

const mobile = useMobile();
const uiSelect = ref(null)

const selectOption = (option) => {
    ordering.value = option.value
    uiSelect.value.hideDropdown();
}

const router = useRouter();
const popupsStore = usePopupsStore();
const authStore = useAuthStore();

const handleResponseClick = (id) => {
    popupsStore.openResponsePopup(id, router)
}

const { anonRequest, authRequest, baseURL } = useFetch();

const setPageMode = (mode) => {
    if (mode === 'filter' && !authStore.isAuthenticated) {
        router.push('/login')
        return
    }

    pageMode.value = mode
}

const loading = ref(false)
const vacancies = ref([])
// const internships = ref([])
// const vacanciesFilter = ref([])
const article = ref(null)
const event = ref(null)
const articles = useArticlesStore()
const events = useEventsStore()




const pagination = reactive({
    page: 1,
    limit: 6,
    totalPages: 1,
})

const pageMode = ref('internships')

watch(pageMode, (newValue, oldValue) => {
    console.log('Page mode changed from', oldValue, 'to', newValue);
    if (newValue === 'internships' || newValue == 'vacancies') {
        pagination.limit = 6
    } else {
        pagination.limit = 10
    }
    fetchVacanciesDataFiltered();
    // Ваш код для обработки изменения pageMode

});

const searchTerm = ref(null);
const filters = ref({
    jobTypes: [],
    cities: [],
    jobShedules: [],
    jobExperiences: [],
    priceFrom: null,
    priceTo: null,
    educations: []
});
const options = ref([
    {
        title: 'Сначала новые',
        value: 'newest',
    },
    {
        title: 'Сначала старые',
        value: 'oldest',
    },
    {
        title: 'По убыванию зарплат',
        value: 'salaryDec',
    },
    {
        title: 'По возрастанию зарплат',
        value: 'salaryInc',
    },

])
const ordering = ref('newest')

const onClearFilters = () => {
    filters.value.jobTypes = []
    filters.value.cities = []
    filters.value.jobShedules = []
    filters.value.jobExperiences = []
    filters.value.priceFrom = null
    filters.value.priceTo = null
    filters.value.educations = []
}

const applyFiltersCallback = () => {

    //  ТУТ НАДО НАПИСАТЬ КОД КОТОРЫЙ ПРИМЕНЯЕТ ФИЛЬТРЫ
    // Задать значения полей переменной filters значениями из стора actionsSheets
    filters.value.jobTypes = actionSheets.filters.filterValues.jobTypes
    filters.value.cities = actionSheets.filters.filterValues.cities
    filters.value.jobShedules = actionSheets.filters.filterValues.jobShedules
    filters.value.jobExperiences = actionSheets.filters.filterValues.jobExperiences
    filters.value.priceTo = actionSheets.filters.filterValues.priceTo
    filters.value.priceFrom = actionSheets.filters.filterValues.priceFrom
    filters.value.educations = actionSheets.filters.filterValues.educations
    filters.value.cities = actionSheets.filters.filterValues.regions
        ? actionSheets.filters.filterValues.regions.map((item) => item.value)
        : [];
    fetchVacanciesDataFiltered();
}

const applyOrderingCallback = () => {

    ordering.value = actionSheets.ordering.value
    fetchVacanciesDataFiltered();
    //  ТУТ НАДО НАПИСАТЬ КОД КОТОРЫЙ ПРИМЕНЯЕТ СОРТИРОВКУ
    // Задать переменной ordering значение из стора actionsSheets
}

watch(() => ordering.value, () => {
    fetchVacanciesDataFiltered()
})

watch(() => pagination.page, (newValue) => {
    fetchVacanciesDataFiltered();
});

const fetchVacanciesData = async (queryParams = {}) => {

    loading.value = true;
    vacancies.value = []
    // if (pageMode.value === 'vacancies')
    //     vacancies.value = []
    // else if (pageMode.value === 'internships')
    //     internships.value = []
    // else if (pageMode.value === 'filter')
    //     vacanciesFilter.value = []
    queryParams.limit = pagination.limit
    queryParams.offset = (pagination.page - 1) * pagination.limit

    try {
        // Создаем строку запроса вручную
        const queryArray = [];
        for (const key in queryParams) {
            const value = queryParams[key];
            // Проверьте, является ли значение массивом
            if (Array.isArray(value)) {
                // Если массив, объедините значения через запятую
                queryArray.push(`${key}=${value.join(',')}`);
            } else {
                queryArray.push(`${key}=${value}`);
            }
        }
        let queryString = queryArray.join('&');
        queryString
        const url = `/vacancies/${queryString ? '?' + queryString : ''}`;


        const response = await anonRequest(url, {
            method: 'GET',
        });

        // Логируем конечный URL

        // Если запрос успешен, сохраняем данные
        if (response && !response.error) {
            vacancies.value = response.results; // Предполагаем, что API возвращает массив данных
            // totalCount.value = response.count
            pagination.totalPages = Math.ceil(response.count / pagination.limit)
        } else {
            // Если есть ошибка, устанавливаем ошибку
            error.value = response;
        }
    } catch (err) {
        console.error('Запрос завершился ошибкой:', err);
    } finally {
        loading.value = false; // Сбрасываем статус загрузки
    }
};

const fetchVacanciesDataFiltered = () => {

    console.log('PAGE MOPDE VALUE IS!Q!!', pageMode.value)
    let queryParams = {}
    if (pageMode.value === 'filter') {

        let jobTypes = []
        filters.value.jobTypes.forEach((element, index) => {
            if (element === true) {
                if (index === 0) {
                    jobTypes.push('full_day')
                } else if (index === 1) {
                    jobTypes.push('not_full_day')
                } else if (index === 2) {
                    jobTypes.push('project')
                } else if (index === 3) {
                    jobTypes.push('internship')
                }
            }

        })
        let jobShedules = []
        filters.value.jobShedules.forEach((element, index) => {
            if (element === true) {
                if (index === 0) {
                    jobShedules.push('full_day')
                } else if (index === 1) {
                    jobShedules.push('shift_work')
                } else if (index === 2) {
                    jobShedules.push('remote')
                } else if (index === 3) {
                    jobShedules.push('flexible')
                }
            }

        })
        let experienceDiapason = []
        filters.value.jobExperiences.forEach((element, index) => {
            if (element === true) {
                if (index === 0) {
                    experienceDiapason.push('no_experience')
                } else if (index === 1) {
                    experienceDiapason.push('years_1_3')
                } else if (index === 2) {
                    experienceDiapason.push('years_3_6')
                } else if (index === 3) {
                    experienceDiapason.push('years_more_6')
                }

            }

        })
        let educations = []
        filters.value.educations.forEach((element, index) => {
            if (element === true) {
                if (index === 0) {
                    educations.push('higher')
                } else if (index === 1) {
                    educations.push('secondary')
                } else if (index === 2) {
                    educations.push('secondary_incomplete')
                } else if (index === 3) {
                    educations.push('no_education')
                } else if (index === 4) {
                    educations.push('empty')
                }
            }

        })


        if (ordering.value === 'newest') {
            queryParams.order_by = '-created_at'
        } else if (ordering.value === 'oldest') {
            queryParams.order_by = 'created_at'
        } else if (ordering.value === 'salaryDec') {
            queryParams.order_by = '-price'
        } else if (ordering.value === 'salaryInc') {
            queryParams.order_by = 'price'
        }

        if (jobTypes.length > 0) {
            queryParams.employment_type__in = jobTypes.join(',')
        }
        if (jobShedules.length > 0) {
            queryParams.employment_schedule__in = jobShedules.join(',')
        }
        if (experienceDiapason.length > 0) {
            queryParams.experience__in = experienceDiapason.join(',')
        }
        if (educations.length > 0) {
            queryParams.education__in = educations.join(',')
        }
        if (filters.value.cities.length > 0) {
            const filtersNoFalse = filters.value.cities.filter(item => item !== false)
            queryParams.region__in = filtersNoFalse.join(',')

        }
        if (filters.value.priceFrom) {
            queryParams.price_from = filters.value.priceFrom
        }
        if (filters.value.priceTo) {
            queryParams.price_to = filters.value.priceTo
        }
    } else if (pageMode.value === 'vacancies') {
        queryParams.employment_type__in = 'full_day,not_full_day,project'
    } else if (pageMode.value === 'internships') {
        queryParams.employment_type__in = 'internship'
    }

    if (searchTerm.value != null) {
        if (searchTerm.value.trim() !== '') {
            queryParams.query = searchTerm.value
        }
    }
    console.log('!!!!!!!!!!!!!!!!!!', queryParams)
    fetchVacanciesData(queryParams)
}


onBeforeMount(() => {
    fetchVacanciesDataFiltered();
    articles.getPublicArticles();
    events.getNears()
    article.value = articles.items[0]
    event.value = events.items[0]
})


</script>
<style scoped>
.shadow {
  box-shadow: 4px 4px 24px 2px rgba(10, 92, 213, 0.1);
  border: 1px solid transparent; 
}

/* Фильтр */
</style>