<template>
  <div ref="dropdownRoot" class="relative w-full">
    <!--  ВНУТРИ ВСЕГДА ДОЛЖНО БЫТЬ ТОЛЬКО ДВА ЭЛЕМЕНТА  -->

    <!--  Если в элементе который будет вставлен в слот trigger переопределить @click обработчик, то нужно вручную открывать и закрывать dropdown
      через функции hide, show и toggle -->
    <slot name="trigger" :isOpen="isOpen" :dropdownRoot="dropdownRoot" :triggerElement="triggerElement" :dropdownElement="triggerElement">
      <div class="bg-purple-700">
        Trigger
      </div>
    </slot>

    <template v-if="isOpen">
      <slot name="dropdown" :isOpen="isOpen" :dropdownRoot="dropdownRoot" :triggerElement="triggerElement" :dropdownElement="triggerElement">
        <div class="bg-purple-700">
          Dropdown
        </div>
      </slot>
    </template>

  </div>


</template>

<script setup>

import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from "vue";

const props = defineProps({
  autoTriggerToogle: {
    default: true,
  },
  customLeftPosition: {
    default: null
  }
})

const dropdownRoot = ref(null)

const triggerElement = computed(() => {
  if (dropdownRoot.value) {
    return dropdownRoot.value.children[0]
  }
  return null
})

watch(() => triggerElement.value, (newElement, oldElement) => {
  if (oldElement) {
    oldElement.removeEventListener('click', toggle)
  }
  if (newElement) {
    if (props.autoTriggerToogle) {
      newElement.addEventListener('click', toggle)
    }
  }
})


const dropdownElement = computed(() => {
  isOpen.value
  if (dropdownRoot.value) {
    return dropdownRoot.value.children[1]
  }
  return null
})


const getDropdownElementPosition = () => {
  if (!triggerElement.value) {
    return new DOMRect(0, 0, 0, 0)
  }
  const triggerRect = triggerElement.value.getBoundingClientRect()
  const dropdownRect = new DOMRect(
    0,
    triggerRect.height,
    0, // Игнорируем
    0, // Игнорируем
  )
  return dropdownRect
}

const isOpen = ref(false)

watch(() => isOpen.value, () => {

  if (isOpen.value) {

    nextTick(() => {
      if (dropdownElement.value) {
        const dropdownPositionRect = getDropdownElementPosition()
        dropdownElement.value.style.position = 'absolute'
        dropdownElement.value.style.left = props.customLeftPosition !== null ? props.customLeftPosition :`${dropdownPositionRect.x}px`
        dropdownElement.value.style.top = `${dropdownPositionRect.y}px`
        dropdownElement.value.style.zIndex = `999`
      }
    })
  }
})


const open = () => {
  
  isOpen.value = true
}

const hide = () => {
  isOpen.value = false
}

const toggle = () => {

  isOpen.value = !isOpen.value
}


const handleClickOutside = (event) => {
  if (isOpen.value && dropdownRoot.value && !dropdownRoot.value.contains(event.target)) {
    hide();
  }
};

const onKeyDown = (event) => {
  if (event.key === 'Escape') {
    hide();
  }
  if (event.key === 'Tab' && isOpen.value) {
    // Переместите фокус на следующий элемент, если это необходимо
  }
};


onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  document.addEventListener('keydown', onKeyDown);
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
  document.removeEventListener('keydown', onKeyDown);
});


defineExpose({
  open,
  hide,
  toggle,
  get isOpen() {
    return isOpen.value; // Пробрасываем текущее значение
  },
})

</script>

<style scoped>

</style>